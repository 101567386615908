import React, { PureComponent } from "react";
import Stamp from "../components/Stamp";
import I18n from "../I18n";
import flags from "../lib/flags";
import Emoji from "../components/Emoji";
import { STATIC_CDN_URL } from "../config/ApiConfig";

class ShowStamp extends PureComponent {
  render() {
    const { stamp, showButtons = false, bookmarks, fav } = this.props;
    if (!stamp) return null;
    const url = STATIC_CDN_URL + "stamp/" + stamp.slug + ".png";
    const isFav = !!fav && fav.indexOf(stamp.item_slug) >= 0;
    const bookmarked = !!bookmarks && bookmarks.indexOf(stamp.item_slug) >= 0;

    return (
      <div
        className="modal alert-backdrop d-flex align-items-center justify-items-center"
      >
        <div className="trans-backdrop link" style={{ zIndex: 0 }} onClick={this.props.closeStamp} />
        <div className="modal-dialog stamp-detail-modal shadow-lg">
          <div className="modal-content">
            <div className="btn-close link" onClick={this.props.closeStamp}>
              <i className="icon-close text-light" />
            </div>
            <div className="stamp-detail-single text-center py-3">
              <Stamp slug={stamp.slug} size={200} />
            </div>
            <div className="bg-stable m-0 stamp-detail-modal-body">
              <div className="mt-2 mx-3">
                {stamp.price >= 2 && (
                  <span className="badge badge-xs badge-gold mr-1">
                    {I18n.t("STAMP_PREMIUM")}
                  </span>
                )}
                {!!stamp.item_set && (
                  <span className="badge badge-xs badge-light mr-1">
                    {I18n.t("STAMP_SET")}
                  </span>
                )}
                {stamp.item_group === "achievement" && (
                  <span className="badge badge-xs badge-light mr-1">
                    {I18n.t("STAMP_ACHIEVEMENTS")}
                  </span>
                )}
                {(stamp.item_group === "int-days" ||
                  stamp.item_group === "festive") && (
                  <span className="badge badge-xs badge-light mr-1">
                    {I18n.t("STAMP_SPECIAL_DAYS")}
                  </span>
                )}
                {!!stamp.country && (
                  <span className="badge badge-xs badge-light mr-1">
                    {I18n.t("STAMP_LOCATION_BASED")}
                  </span>
                )}
              </div>
              <div className="stamp-pop text-left">
                <h5 className="text-primary font-weight-bold mb-1 p-0">
                  {I18n.t(stamp.name)}
                </h5>
                <p>
                  {!!stamp.country && (
                    <Emoji
                      code={flags[stamp.country].emoji}
                      size={17}
                      className="mr-1"
                    />
                  )}
                  {!!stamp.country && !stamp.item_set_id
                    ? I18n.country(stamp.country)
                    : I18n.t(stamp.desc)}
                </p>
              </div>
              {!!showButtons && (
                <div className="d-flex flex-row">
                  <a
                    href={url}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-stable btn-showstamp"
                  >
                    <i className="icon-download text-lighter" />
                  </a>
                  <button
                    className="btn btn-stable btn-showstamp"
                    onClick={()=>{
                      this.props.toggleFav(stamp)
                    }}
                  >
                    <i className={ isFav ? "icon-heart text-danger" : "icon-heart-o text-lighter"} />
                  </button>
                  <button
                    className="btn btn-stable btn-showstamp"
                    onClick={()=>{
                      this.props.toggleBookmark(stamp)
                    }}
                  >
                    <i className={ bookmarked ? "icon-bookmark text-secondary" : "icon-bookmark-o text-lighter"} />
                  </button>
                  <button
                    className="btn btn-stable btn-showstamp"
                    onClick={this.props.hideStamp}
                  >
                    <i className="icon-eye-off text-lighter" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowStamp;
