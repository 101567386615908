import React, { Component } from "react";
import { connect } from "react-redux";
import flags from "../lib/flags";
import Emoji from "../components/Emoji";
import { reProfileMe, reUnlocked } from "../selectors";
import {
  getPromos,
  getGlobalOverview,
  getPromoSchedule,
  getStoreItemInfo,
} from "../api/slowly.api";

import { Link } from "react-router-dom";
import StampItem from "../components/StampItem";
import _ from "lodash";
import I18n from "../I18n";
import moment from "../lib/moment";

import en_country from '../I18n/countries/en'

class StampStoreOverview extends Component {
  constructor(props) {
    super(props);

    const {
      cachedStore = {
        random: [],
        single: [],
        set: [],
      },
    } = this.props;

    this.state = {
      ...cachedStore,
      store: this.props.me.location_code,
      locationTotal: 0,
      limited: [],
      loading: true,
      feedbackURL: "https://feedback.slowly.app/stamp-requests",
    };
  }

  componentDidMount() {
    this._syncStore(); 
  }

  componentDidUpdate(prevProps) {
    if(this.unmounted) return false;
    if (prevProps.unlocked.length !== this.props.unlocked.length) {
      global.log("unlocked stamps changed, map again", {
        prev: prevProps.unlocked,
        new: this.props.unlocked,
      });
      this.mapPromoData({
        limited: this.state.limited,
        location: this.state.location,
        random: this.state.random,
        single: this.state.single,
        set: this.state.set,
      });
    }
  }

  componentWillUnmount(){
    this.unmounted = true
  }

  _syncStore = async () => {
    const { token } = this.props.me;
    const { cachedStore } = this.props

    try {
      const syncResult = await getPromos(token); 
      global.log('Store syncResult', syncResult)

      const { limited = [], location = [], store, timezone } = syncResult;

      if (!syncResult.status) {
        this.setState({
          isClosed: true,
        });
        return false;
      }else{
        this.setState({
          store, timezone
        })
      }

      this.props.refreshCoins(syncResult.coins, 'overview__syncStore');

      if (
        //maximum keep 4 hours
        !!cachedStore.last_sync &&
        moment.orig(cachedStore.last_sync).add(4, 'hours') > moment.orig() &&
        //store must be same
        cachedStore.store === store &&
        cachedStore.latest >= syncResult.latest &&
        !!cachedStore.single &&
        !!cachedStore.set
      ) {
        global.log('load cached result');

        this.mapPromoData({
          store,
          timezone,
          limited,
          location,
          random: cachedStore.random,
          single: cachedStore.single,
          set: cachedStore.set,
          loading: false,
        });

        this.props.updateStore({
          latest: syncResult.latest,
          store,
          timezone,
          limited,
          location,
          new: false,
          status: 2,
        });
      } else {
        global.log("sync again");
        const refreshedResult = await getGlobalOverview(token); //cacheable

        this.mapPromoData({
          store,
          timezone,
          limited,
          location,
          random: refreshedResult.random,
          single: refreshedResult.single,
          set: refreshedResult.set,
          loading: false,
        });

        this.props.updateStore({
          latest: syncResult.latest,
          status: 2,
          new: false,
          location,
          random: refreshedResult.random,
          single: refreshedResult.single,
          set: refreshedResult.set,
          store,
          timezone,
          last_sync: moment.orig().format(),
        });

        this.props.syncSlowly(this.props.me.token);
      }
    } catch (error) {
      global.log("error", error);
      if(this.unmounted) return false;
      
      this.setState({
        loading: false,
        isClosed: true,
      });
      return false;
    }
  };

  mapPromoData = (payload) => {
    if(this.unmounted) return false;
    const { unlocked } = this.props;
    global.log("mapPromoData!", payload);

    this.setState({
      ...payload,
      random: _.map(payload.random, (d) => {
        const extraInfo = getStoreItemInfo(d);
        const salesInfo = getPromoSchedule(d, this.state.timezone);

        return {
          ...d,
          ...extraInfo,
          unlocked: false,
          salesInfo,
        };
      }),
      limited: _.map(payload.limited, (d) => {
        const extraInfo = getStoreItemInfo(d);
        const salesInfo = getPromoSchedule(d, this.state.timezone);

        return {
          ...d,
          ...extraInfo,
          unlocked:
            d.stamps.length > 0
              ? unlocked.indexOf(d.stamps[0].slug) >= 0
              : false,
          salesInfo,
        };
      }),
      location: _.take(
        _.map(payload.location, (d) => {
          const extraInfo = getStoreItemInfo(d);
          const salesInfo = getPromoSchedule(d, this.state.timezone);

          return {
            ...d,
            ...extraInfo,
            unlocked:
              d.stamps.length > 0
                ? unlocked.indexOf(d.stamps[0].slug) >= 0
                : false,
            salesInfo,
          };
        }),
        4
      ),
      locationTotal: this.state.locationTotal===0 ? payload.location.length : this.state.locationTotal,
      single: _.take(
        _.map(payload.single, (d) => {
          const extraInfo = getStoreItemInfo(d);
          const salesInfo = getPromoSchedule(d, this.state.timezone);

          return {
            ...d,
            ...extraInfo,
            unlocked:
              d.stamps.length > 0
                ? unlocked.indexOf(d.stamps[0].slug) >= 0
                : false,
            salesInfo,
          };
        }),
        4
      ),
      set: _.take(
        _.orderBy(
          _.map(payload.set, (d) => {
            const extraInfo = getStoreItemInfo(d);
            const salesInfo = getPromoSchedule(d, this.state.timezone);

            return {
              ...d,
              ...extraInfo,
              unlocked:
                d.stamps.length > 0
                  ? unlocked.indexOf(d.stamps[0].slug) >= 0
                  : false,
              salesInfo,
            };
          }),
          "discount"
        ),
        4
      ),
    });

    global.log("Stamp store this.state", this.state);
  };

  render() {
    const {
      loading,
      limited = [],
      location = [],
      random = [],
      single = [],
      set = [],
      feedbackURL = null,
    } = this.state;

    if (!!loading) {
      return (
        <div className="text-lighter text-center mt-5">
          <small>
            <span
              className="spinner-grow spinner-grow-sm mr-2 text-warning"
              role="status"
              aria-hidden="true"
            />
            {I18n.t("LOADING")}
          </small>
        </div>
      );
    }

    return (
      <div className="pt-2">
          { this.state.locationTotal < 8 && flags[this.state.store] && (
              <div className="alert alert-primary py-3 w-100 my-2">
                <h4 className="alert-heading mt-2">
                  <Emoji
                    code={flags[this.state.store].emoji}
                    size={24}
                    className="mr-2"
                  />
                  {I18n.t("NEW_COUNTRY", {
                    country: I18n.country(this.props.cachedStore.store),
                  })}
                </h4>
                <p>{
                  this.state.locationTotal===0 ? I18n.t('NO_EXCLUSIVE_MSG', {
                    region: I18n.country(this.props.cachedStore.store),
                  }) : 
                  I18n.t("NO_EXCLUSIVE_TITLE")
                }</p>
                <a
                  href={"https://feedback.slowly.app/stamp-requests"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  <Emoji code="💬" size={16} className="mr-1" />
                  {I18n.t("NO_EXCLUSIVE_SUGGEST")}
                </a>
                <a
                  href={"https://feedback.slowly.app/stamp-requests?search="+en_country[this.props.cachedStore.store]}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-secondary ml-2"
                >
                  <Emoji code="🔺" size={16} className="mr-1" />
                  {I18n.t("NO_EXCLUSIVE_CTA", {
                    region: I18n.country(this.props.cachedStore.store),
                  })}
                </a>
              </div>
            )}
        {limited.length > 0 && (
          <div className="store-item-group-wrapper" ref={this._limited}>
            <div className=" border-bottom mb-3">
              <div className="text-darker pl-1 mb-2">
                <Emoji code={"⏰"} size={16} className="mr-2" />
                {I18n.t("STAMP_LIMITED_GET_NOW")}
              </div>
            </div>
            <div className="store-item-group row">
              {limited.map((item) => (
                <StampItem
                  item={item}
                  key={"item-" + item.id}
                  showStamp={() => {
                    this.props.showStamp(item);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {location.length > 0 && this.state.store && flags[this.state.store] && (
          <div className="store-item-group-wrapper">
              <div className="d-flex border-bottom mb-3 align-items-center">
              <Link className="text-darker pl-1 mb-2 mr-auto" to="/store/list/exclusive">
                <Emoji
                  code={flags[this.state.store].emoji}
                  size={16}
                  className="mr-2"
                />
                {I18n.country(this.state.store)}
              </Link>
              <Link
                className="link-color mr-2 small"
                to="/store/list/exclusive"
              >
                {I18n.t("See All")} <i className="icon-chevron-right" />
              </Link>
            </div>
            <div className="store-item-group row">
              {location.map((item) => (
                <StampItem
                  item={item}
                  key={"item-" + item.id}
                  showStamp={() => {
                    this.props.showStamp(item);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {random.length > 0 && (
          <div className="store-item-group-wrapper" ref={this._random}>
            <div className="border-bottom mb-3">
              <div className="text-darker pl-1 mb-1 mr-auto">
                <Emoji
                  code={'🎲'}
                  size={16}
                  className="mr-2"
                />
                {I18n.t("RANDOM_STAMP")}
              </div>
            </div>
            <div className="store-item-group row">
              {random.map((item) => (
                <StampItem
                  item={item}
                  key={"item-" + item.id}
                  showStamp={() => {
                    this.props.showStamp(item);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {single.length > 0 && (
          <div className="store-item-group-wrapper" ref={this._single}>
            <div className="border-bottom mb-3 d-flex align-items-center">
            <Link className="text-darker pl-1 mb-2 mr-auto" to="/store/list/single">
                {I18n.t("STAMP_SINGLE")}
              </Link>
              <Link className="link-color mr-2 small" to="/store/list/single">
                {I18n.t("See All")} <i className="icon-chevron-right" />
              </Link>
            </div>
            <div className="store-item-group row">
              {single.map((item) => (
                <StampItem
                  item={item}
                  key={"item-" + item.id}
                  showStamp={() => {
                    this.props.showStamp(item);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        { feedbackURL && this.state.locationTotal>8 ? (
          <div style={{ position: "absolute", right: 15, top: 19 }}>
            <a
              href={feedbackURL}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-light btn-sm mt-1"
            >
              <i className="icon-stamp-small-01 text-calm mr-1" />
              {I18n.t("Stamp Request")}{" "}
            </a>
          </div>
        ) : set.length > 0 && (
          <div className="store-item-group-wrapper" ref={this._set}>
            <div className="border-bottom mb-3 d-flex align-items-center">
              <Link className="text-darker pl-1 mb-2 mr-auto" to="/store/list/set">
                {I18n.t("STAMP_SET")}
              </Link>
              <Link className="link-color mr-2 small" to="/store/list/set">
                {I18n.t("See All")} <i className="icon-chevron-right" />
              </Link>
            </div>
            <div className="store-item-group row">
              {set.map((item) => (
                <StampItem
                  item={item}
                  key={"item-" + item.id}
                  showStamp={() => {
                    this.props.showStamp(item);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    unlocked: reUnlocked(state),
    cachedStore: state.slowly.store
  };
};

const updateStore = function updateStore(store) {
  return {
    type: "SYNC_STORE_COMPLETE",
    store,
  };
};

const refreshCoins = function refreshCoins(coins, from='overview') {
  return {
    type: "REFRESH_COINS",
    coins,
    from
  };
};

const syncSlowly = function syncSlowly(token) {
  return {
    type: "CHECK_SLOWLY",
    token,
  };
};
const refreshStamps = function refreshStamps(isSet = false) {
  return {
    type: "REFRESH_STAMPS",
    isSet,
  };
};

const refreshStampsSuccess = function refreshStampsSuccess({
  items = null,
  newStamps = null,
  isSet = null,
}) {
  return {
    type: "REFRESH_STAMPS_SUCCESS",
    items,
    newStamps,
    isSet,
  };
};


export default connect(mapStateToProps, {
  updateStore,
  refreshCoins,
  syncSlowly,
  refreshStamps,
  refreshStampsSuccess
})(StampStoreOverview);
