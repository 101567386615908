import React, { Component } from "react";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import I18n from "../I18n";
import { checkPromo, getData, checkoutSession } from "../api/payment.api";
import { toast } from "react-toastify";
import tracking from "../lib/tracking";
import Hashids from "hashids";
import _ from "lodash";
import moment from "../lib/moment";

const hashids = new Hashids(
  "+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B",
  30
);

class Topup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coinsdata: null,
      selectedIndex: -1,
      save: true,
      loading: false,
      stripe: null,
      tooLong: false,
      ready: false,
    };
  }

  componentDidMount() {
    this.setup();
  }

  selectProduct = (index) => {
    if (!!this.state.loading) return false;
    this.setState({
      selectedIndex: index,
    });
  };

  setup = async () => {
    const { token, location_code } = this.props.me;
    const result = await getData({
      token,
    });
    global.log("getData", result);

    const activePromo = await checkPromo({
      token,
    });
    global.log("promo", activePromo);

    this.setState({
      promo: !!activePromo.promo ? activePromo : null,
      coinsdata: result.coinsdata,
      ready: true,
      currency: result.currency,
      tax: result.tax,
      location_code: result.location_code,
    });

    if (result.coinsdata.length === 1) {
      this.setState({
        selectedIndex: 0,
      });
    }
  };

  lockProductList = () => {
    this.setState({
      locked: true,
    });
  };

  unlockProduct = () => {
    this.setState({
      locked: false,
    });
  };

  continue = async () => {
    const { coinsdata, selectedIndex } = this.state;
    this.setState({ loading: true });

    try {
      const multiplier = !!this.state.promo ? this.state.promo.multiplier : 1;

      const product_hash = hashids.encode(
        this.props.me.id,
        coinsdata[selectedIndex].id,
        coinsdata[selectedIndex].coins,
        multiplier
      );

      const _checkout = await checkoutSession({
        token: this.props.me.token,
        payload: {
          product_id: coinsdata[selectedIndex].id,
          currency: this.state.currency,
          device_id: this.props.uuid,
          locale: this.props.locale,
          mode: "payment",
          product_hash,
          promo: !!this.state.promo ? this.state.promo.promo : null,
        },
      });
      global.log("_checkout", _checkout);

      if (_checkout.url) {
        window.top.location.href = _checkout.url;
        return true;
      }
    } catch (error) {
      global.log("stripe error", error);

      this.props.showError({
        message: "UNABLE_TO_REFRESH_DATA",
      });
    }

    this.setState({ loading: false });
  };

  render() {
    const { coinsdata, selectedIndex, ready, promo } = this.state;

    if (!ready) {
      return (
        <div className="w-100 store-wrapper text-center pt-5">
          <small>
            <span
              className="spinner-grow spinner-grow-sm mr-2 text-warning"
              role="status"
              aria-hidden="true"
            />
            {I18n.t("LOADING")}
          </small>
        </div>
      );
    }

    return (
      <div className="container px-5" dir="ltr">
        <div className="p-4 position-relative">
          <div className="row mt-5">
            <div className="col col-6">
              <div className="sticky-top pt-3" style={{ top: 80, zIndex: -1 }}>
                <img
                  src="https://promo.slowly.app/banner_coins.png"
                  alt={I18n.t("PURCHASE_COINS")}
                  className="w-100 coins-banner"
                />
              </div>
            </div>
            <div className="col " style={{ maxWidth: 640 }}>
              {!this.state.locked && (
                <>
                  {!!promo ? (
                    <h2 className="text-danger mb-1">
                      {I18n.t("SPECIAL_OFFER")}
                    </h2>
                  ) : (
                    <h2 className="text-primary mb-1">
                      {I18n.t("PURCHASE_COINS")}
                    </h2>
                  )}
                  {!!promo ? (
                    <div>
                      {promo.title
                        ? promo.title
                        : I18n.t("STAMP_LIMITED_GET_NOW")}
                      <br />
                      <span className="small text-lighter">
                        <i className="icon-clock" />{" "}
                        {" " + moment.custom(promo.expire_at, "long")}
                      </span>
                    </div>
                  ) : (
                    I18n.t("SELECT_PURCHASE_COINS")
                  )}
                  <ul className="list-group mt-3">
                    {coinsdata.map((item, index) => {
                      return (
                        <button
                          key={item.id}
                          className={
                            "list-group-item list-group-item-action d-flex justify-content-between align-items-center px-3"
                          }
                          onClick={() => {
                            this.selectProduct(index);
                          }}
                        >
                          <i
                            className={
                              index === selectedIndex
                                ? "icon-checkbox-checked checkbox mr-3 text-primary"
                                : "icon-checkbox-unchecked mr-3 checkbox  text-lighter"
                            }
                          />
                          <div className="flex-grow-1 text-light">
                            {item.coins + " " + I18n.t("COINS")}
                            {!!promo ? (
                              <span className="text-danger small ml-2">
                                {" + " +
                                  (item.coins * this.state.promo.multiplier -
                                    item.coins) +
                                  " " +
                                  I18n.t("COINS")}
                              </span>
                            ) : (
                              !!item.description && (
                                <span className="text-danger small ml-2">
                                  {I18n.t(item.description)}
                                </span>
                              )
                            )}
                          </div>
                          <strong className="pr-1 text-primary">
                            {item.symbol}
                            {item.no_decimal
                              ? I18n.toNumber(item.amount, { precision: 0 })
                              : item.amount}
                          </strong>
                        </button>
                      );
                    })}
                  </ul>
                  <div className="p-2 d-flex mt-2 small">
                    <div className="text-light">
                      {I18n.t("CURRENCY_WITH_CODE", {
                        code: this.state.currency,
                      })}
                    </div>
                    <div className="flex-fill text-right mr-2">
                      {!!this.state.tax ? (
                        <span className="">
                          {" + " + this.state.tax.type + " "}
                          {selectedIndex >= 0
                            ? coinsdata[selectedIndex].symbol +
                              coinsdata[selectedIndex].tax
                            : this.state.tax.rate + "%"}
                          <span className="text-light ml-2">
                            {"( "}
                            {/* <i className="icon-pin text-calm mr-1" /> */}
                            {I18n.country(this.state.location_code)}
                            {" )"}
                          </span>
                          {selectedIndex >= 0 && (
                            <h5 className="mt-1">
                              <span className="mr-2">{I18n.t("Total")}</span>
                              {coinsdata[selectedIndex].symbol}
                              {coinsdata[selectedIndex].amount_after_tax}
                            </h5>
                          )}
                        </span>
                      ) : (
                        <span className="text-light ml-2 small">
                          <i className="icon-pin text-lighter small" />
                          {" " + I18n.country(this.state.location_code)}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
              {!(
                this.state.location_code === "IN" ||
                this.state.location_code === "RU"
              ) ? (
                <button
                  type="button"
                  className="btn btn-primary btn-block mt-2"
                  onClick={this.continue}
                  disabled={this.state.loading || this.state.selectedIndex<0}
                >
                  {I18n.t("CONTINUE")}{" "}
                </button>
              ) : (
                <div className="p-2">
                  <i className="icon-info mr-2 text-danger" />
                  Please purchase via our mobile app.
                </div>
              )}
              <div className="mt-5">
                <a
                  className="no-underline span-lighter mb-3"
                  alt={I18n.t("Secure payment powered by Stripe")}
                  href="https://www.stripe.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <small className="text-light">
                    <i className="icon-lock mr-1" />{" "}
                    {I18n.t("Secure payment powered by Stripe")}{" "}
                  </small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale,
    uuid: state.slowly.uuid,
  };
};

const refreshCoins = function refreshCoins(coins, from = "topup") {
  return {
    type: "REFRESH_COINS",
    coins,
    from,
  };
};

const showError = function showError(error) {
  return {
    type: "SHOW_ERROR",
    error,
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  showError,
})(Topup);
