import React, { PureComponent } from "react";
import Emoji from "../components/Emoji";
import emojiSupport from 'detect-emoji-support'

class AppAlert extends PureComponent {
  render() {
    const { alert } = this.props;
    if (!alert) return null;
    const w = (!alert.cancelLabel || !!alert.btnFullWidth) ? "w-100 mx-0 my-1" : "w-50"
    const block = (!alert.cancelLabel || !!alert.btnFullWidth) ? 'd-block' : ''

    return (
        <div className="modal alert-backdrop d-flex align-items-center justify-items-center">
          <div className="modal-dialog alert-modal shadow-lg">
            <div className="modal-content px-3 pt-3">
              { !!alert.emoji && (
                <div className="px-3 pt-2">
                  { emojiSupport() ? (<h2 className="m-0">{alert.emoji}</h2>) : (
                    <Emoji code={alert.emoji} size={36} />
                  )}
                </div>
              )}
              { !!alert.title && (
              <div className="px-3 pt-3 pb-1">
                <h5 className="modal-title">{ alert.title }</h5>
              </div>
              )}
              <div className="modal-body">
                { alert.message==='LOADING' ? (<div className="d-flex justify-content-center">
                  <div className="spinner-border text-calm my-5" role="status">
                    <span className="sr-only">...</span>
                  </div>
                </div>) :
                !!alert.message && (<div className="pb-3">{ alert.message }</div>)}
                {alert.dontask && (
                  <div className="mt-2">
                    <button className="btn btn-stable text-light p-0" onClick={this.props.dontaskToggle}>
                      <i className={ this.props.dontaskValue ? "icon-checkbox-checked mr-1" : "icon-checkbox-unchecked mr-1"} /> {alert.dontask}
                    </button> 
                  </div>
                )}
              </div>
              { (!!alert.cancelLabel || !!alert.confirmLabel) && (
                <div className={"modal-footer justify-content-center mx-n3 px-4 " + block}>
                  { !!alert.cancelLabel &&
                    (<button type="button" className={"btn btn-outline-light " +w} onClick={alert.cancelAction}>{alert.cancelLabel}</button>)
                  }
                  { !!alert.confirmLabel && (
                    <button type="button"
                      className={ alert.danger ? "btn btn-danger " + w : "btn btn-primary " +w }
                      onClick={alert.confirmAction}>{alert.confirmLabel}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
    );
  }
}

export default AppAlert;
