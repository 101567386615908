import React, { Component } from "react";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import StampDetailModal from "../components/StampDetailModal";
import StampStoreOverview from "../components/StampStoreOverview";
import StampStoreList from "../components/StampStoreList"; 
import StampStoreRandom from "../components/StampStoreRandom"; 
import StampStoreMenu from "../components/StampStoreMenu";
import _ from "lodash";
import hashids from "../lib/hashids"

import I18n from "../I18n";
import { regPollfish, checkPollfish, pollfishDecline } from "../api/slowly.api";
import { syncCoins } from "../api/me.api"
// import InitSurveyModal from "../components/InitSurveyModal";
// import SurveyModal from "../components/SurveyModal";
// import AdComponent from "../components/AdComponent";
import AppAlert from "../components/AppAlert";
import { toast } from "react-toastify";
import {
  CDN_URL
} from "../config/ApiConfig"
import moment from "moment"
// import coin from "../assets/images/coin.png";

class StampStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showing: null,
      showingStamp: null,
      showSurvey: false,
    };
    this.refreshStampThrottle = _.throttle(this.refreshStamps, 10000, { 'leading': false, 'trailing': true });
  }

  componentDidMount(){
    // if(!this.props.pollfishDeclined) this.tryPollfish()

    const {
      email, name, id, gender, avatar, created_at
      // location_code
     } = this.props.me

    if(!!email){
      const avatarURL = avatar.length <=2 ? CDN_URL + "/assets/images/avatar/"+gender + "/" + avatar + ".png"
                                : CDN_URL + "/assets/images/avatar/other/" + avatar + ".png"
      window['identifyCanny']({
        // Replace these values with the current user's data
        email,
        name,
        id,
        // These fields are optional, but recommended:
        avatarURL,
        created: moment(created_at).format()
      })
      // this.setState({
      //   feedbackURL: 'https://feedback.slowly.app/changelog'
      // })
    }
  }

  componentWillUnmount() { 
    global.log("store onunmount, flush now");
    this.refreshStampThrottle.flush();
  }

  receivedOffer = (data) => {
    global.log("[Pollfish] receivedOffer", data);
    if (data.reward_value >= 10) {
      this.setState({
        surveyAvailable: true,
        reward_value: data.reward_value,
        time_req: data.survey_loi,
      });
    }
  };

  notEligible = (e) => {
    global.log("[Pollfish] notEligible", e);
    this.setState({
      surveyAvailable: false,
    });
    setTimeout(this.checkReason, 2000);
  };

  checkReason = async () => {
    try {
      const getjson = await checkPollfish({
        token: this.props.me.token,
        locale: "en",
        device_id: this.props.uuid,
      });
      if (!!getjson.extra && getjson.extra === "vpn") {
        toast.info(
          "Survey not rewarded - You seem to be using a VPN or proxy. Please turn off any of these services and try again later.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true,
          }
        );
      } else {
        toast.info(
          "Unfortunately, you were not eligible for this survey and you will not get any reward for it. Please stay tuned for future surveys.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false,
            closeOnClick: true,
          }
        );
      }
    } catch (error) {
      global.log("checkReason error");
    }
  };

  tryPollfish = async () => {
    const knowLanguageCheck = _.find(this.props.me.lang, (o) => {
      return o.slug === "en" && o.level >= 3;
    });
    global.log("[pollfish] knowLanguageCheck", knowLanguageCheck);

    if (this.props.locale === "en" || !!knowLanguageCheck) {
      try {
        const getjson = await checkPollfish({
          token: this.props.me.token,
          locale: "en",
          device_id: this.props.uuid
        });
        global.log("[pollfish] getjson", getjson);

        if (getjson.json) {
          const result = await regPollfish({
            ...getjson.json, 
            // "version": "5",
            // "device_descr" : "Web",
            // "scr_h" : "500",
            // "scr_w" : "400",
            // "app_name" : "Slowly",
            // "app_category" : "social",
            offerwall: true,
            debug: false
          });
          if (result) {
            this.setState({
              showSurvey: false,
              surveyAvailable: true,
              url: result,
            });
          }
          global.log("[pollfish] reg result", result);
        }
      } catch (error) {
        global.log("[pollfish] error", error);
      }
    }
  };

  _pollfishDecline = async () => {
    this.setState({
      url: null,
      showSurvey: false,
      surveyAvailable: false,
    });
    document.body.classList.remove("modal-open");

    this.props._declinePolfish();
  };

  userRejectedSurvey = async () => {
    this.setState({
      surveyAvailable: false,
      initSurvey: false,
      alert: null,
    });
    try {
      const result = await pollfishDecline({ token: this.props.me.token });
      global.log("pollfishDecline result", result);
    } catch (error) {
      global.log("pollfishDecline error", error);
    }
    this.props._declinePolfish();
  };

  onSuccess = async (coins) => {
    this.setState({
      surveyAvailable: false,
    });

    try {
      const record = await syncCoins({
        token: this.props.me.token,
      });
      if (record.coins > this.props.me.coins) {
        toast.info("💰 " + I18n.t("COINS_ADDED", { coins: coins }), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          closeOnClick: true,
        });
      }
      this.props.refreshCoins(record.coins);
    } catch (error) {
      global.log("syncCoins error", error);
    }
  };

  initSurvey = () => {
    this.setState({
      initSurvey: true,
    });
  };

  closePollfish = () => {
    this.setState({
      showSurvey: false,
    });
    document.body.classList.remove("modal-open");
  };

  showPollfish = () => {
    this.setState({
      initSurvey: false,
    });

    setTimeout(() => {
      this.setState({
        showSurvey: true,
      });
      document.body.classList.add("modal-open");
    }, 500);
  };

  closePopup = () => {
    this.setState({
      initSurvey: false,
      alert: {
        // emoji: "🔔",
        title:
          "Would you be interested in a similar market survey in the future?",
        message: null,
        cancelLabel: "Do not show again",
        cancelAction: this.userRejectedSurvey,
        confirmLabel: "Interested",
        confirmAction: this.closeAlert,
      },
    });
  };

  closeAlert = () => {
    this.setState({ alert: null, surveyAvailable: false });
  };

  refreshStamps = () => {
    global.log('refresh stamps now');
    this.props.refreshStamps()
  }
  
  showStamp = (item) => {
    if(item.collection_type==='random'){
      this.setState({ showingRandom: item })
      this.props.history.push("/store/random/"+hashids.encode(item.id));
    }else if(item.isSeries){
      this.setState({ seriesTitle: item.title })
      this.props.history.push("/store/series/"+item.collection_type);
    }else{
      this.setState({
        showing: item,
        showStampModal: true,
        checking: true
      });
    }
  };

  hideStamp = () => {
    this.setState({
      showStampModal: false,
    });
  };

  purchaseCoins = () => {
    this.closeAlert();
    this.props.history.push("/topup");
  };

  render() {
    const {
      showing = false,
      showStampModal = false
    } = this.state;
    
    const { params=null } = this.props.match
    return (
      <div className="w-100 store-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <StampStoreMenu 
                showStamp={this.showStamp}
              />
              {/* {!!this.state.surveyAvailable && (
                <button
                  type="button"
                  className="btn btn-light badge badge-pill badge-light p-1 px-2 mr-2 font-weight-bold"
                  onClick={this.initSurvey}
                >
                  <img src={coin} height={14} alt={I18n.t("MY_COINS")} />{" "}
                  {I18n.t("SURVEY")}
                </button>
              )} */}
            </div>
            <div className="col-9">
              { !!params && params.mode==='list' ? (
                <StampStoreList
                  showStamp={this.showStamp}
                  list={params.list}
                />
              ) : !!params && params.mode==='random' ? (
                <StampStoreRandom
                  item={this.state.showingRandom}
                  list={params.list}
                  newPurchase={this.refreshStampThrottle}
                />
              ) : !!params && params.mode==='series' ? (
                <StampStoreList
                  showStamp={this.showStamp}
                  list={'series'}
                  collection={params.list}
                  seriesTitle={this.state.seriesTitle}
                />
              ): (
                <StampStoreOverview
                  showStamp={this.showStamp}  
                />
              )}
            </div>
          </div>
        </div>
        <StampDetailModal
          showing={showing}
          showStampModal={showStampModal}
          hideStamp={this.hideStamp}
          purchaseCoins={this.purchaseCoins}
          newPurchase={this.refreshStampThrottle}
        />

        {/* <SurveyModal
          close={this.closePollfish}
          closeAndNoShow={this._pollfishDecline}
          onOfferReceived={this.receivedOffer}
          notEligible={this.notEligible}
          userRejectedSurvey={this.userRejectedSurvey}
          onSuccess={this.onSuccess}
          show={this.state.showSurvey}
          url={this.state.url}
        /> */}
        {/* <InitSurveyModal
          show={this.state.initSurvey}
          handleClose={this.closePopup}
          reward_value={this.state.reward_value}
          time_req={this.state.time_req}
          showPollfish={this.showPollfish}
        /> */}
        <AppAlert alert={this.state.alert} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    uuid: state.slowly.uuid,
    pollfishDeclined: !!state.slowly.pollfishDeclined,
  };
};

const refreshCoins = function refreshCoins(coins, from='stampStore') {
  return {
    type: "REFRESH_COINS",
    coins,
    from
  };
};

const syncSlowly = function syncSlowly(token) {
  return {
    type: "CHECK_SLOWLY",
    token,
  };
};
const refreshStamps = function refreshStamps(isSet = false) {
  return {
    type: "REFRESH_STAMPS",
    isSet,
  };
};

const refreshStampsSuccess = function refreshStampsSuccess({
  items = null,
  newStamps = null,
  isSet = null,
}) {
  return {
    type: "REFRESH_STAMPS_SUCCESS",
    items,
    newStamps,
    isSet,
  };
};

const refreshFriend = function refreshFriend(postID) {
  return {
    type: "REFRESH_FRIEND",
    postID,
  };
};

const _declinePolfish = function _declinePolfish() {
  return {
    type: "POLLFISH_DECLINE",
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  syncSlowly,
  refreshFriend,
  refreshStamps,
  refreshStampsSuccess,
  _declinePolfish
})(StampStore);
