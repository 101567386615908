import React, { PureComponent } from 'react';
import emoji from '../lib/emoji'
// import emojiSupport from 'detect-emoji-support'

class Emoji extends PureComponent {
  render() {
    const { size=12, code, className } = this.props;
    if(!code) return null;
    const result =emoji(code)
    if(!result) return null
    
    return (
      <span
        style={{ fontSize: size, opacity: 1 }}
        className={className}
      >
        { result }
      </span>
    )
  }
}
export default Emoji;
