import {
  GOT_TOKEN,
  CHECK_DOB,
  GET_ME_SUCCESS,
  INIT_APP_COMPLETE,
  CHECK_INCOMING,
  GET_UNREAD,
  GET_FRIENDS,
  REFRESH_STAMPS,
  REFRESH_STAMPS_SUCCESS,
  ME_ERROR,
  SHOW_ERROR,
  RESET_ERROR,
  UPDATE_PREF,
  UPDATE_PREF_SUCCESS,
  UPDATE_ME,
  UPDATE_ME_SUCCESS,
  UPDATE_BOOKMARKS,
  UPDATE_BOOKMARKS_SUCCESS,
  UPDATE_FAV,
  UPDATE_FAV_SUCCESS,
  LANG_INIT,
  LANG_ADD_SUCCESS,
  LANG_UPDATE_SUCCESS,
  LANG_REMOVE_SUCCESS,
  SAVE_EXPLORE_FILTERS,
  SYNC_EXCLUDE_TOPICS,
  SYNC_EXCLUDE_TOPICS_SUCCESS,
  GET_COLLECTION_SUCCESS,
  LOGOUT,
  LOGOUT_COMPLETE,
  REFRESH_COINS,
  SYNC_STORE_COMPLETE
} from "../actions/type";

import { call, put, select, take, takeLatest } from "redux-saga/effects";

import {
  getToken,
  getMyID,
  getUUID,
  getPhotoQuota,
  reUnlocked,
  getStore,
  getTrust
} from "../selectors";

import {
  getMe,
  getStamps,
  syncCollection,
  refreshWebToken,
  updateMe,
  updatePref,
  unlockStamp,
  clearToken,
  syncExcludeTopics,
  addLang,
  updateLang,
  removeLang,
  getStampData,
  syncStampBookmark,
  syncStampFav,
  checkAchievement
} from "../api/me.api";

import { checkStore } from "../api/slowly.api";
import { toast } from "react-toastify";
import I18n from "../I18n";

import tracking from "../lib/tracking";
import {
  browserName,
  browserVersion,
  osName,
  osVersion
} from "react-device-detect";

import moment from "moment";
import _ from "lodash";

function mapMe({ me, photoQuota, skip = false }) {
  if (me.pref !== undefined ) {
    me.pref = JSON.parse(me.pref);
  }
  if (!!me.avatarbuilder)
    me.avatarbuilder.value = JSON.parse(me.avatarbuilder.value);

  if (!!me.dob_privacy) me.dob_privacy = parseInt(me.dob_privacy);
  if (!!me.role) me.role = parseInt(me.role);
  if (!!me.status) me.status = parseInt(me.status);
  if (!!me.new_request) me.new_request = parseInt(me.new_request);
  if (!!me.show_last_login) me.show_last_login = parseInt(me.show_last_login);
  if (!!me.excluded_countries || me.excluded_countries === "") {
    if (me.excluded_countries.length >= 1)
      me.excluded_countries = me.excluded_countries.split(",");
    else me.excluded_countries = [];
  }

  if (!!me.tagged) {
    me.tags = me.tagged.map(item => item.tag_slug);
    delete me.tagged;
  }

  if (!!me.items) me.items = _.uniqBy(me.items, "item_slug");

  if(!!me.newStamps)
    if(me.newStamps.length>0){
      me.showStampNotify = true;

      if(me.newStamps.length>1){
        toast.warn( I18n.t("GOT_MULTI_NEW_STAMPS") );
      }else{
        toast.warn( I18n.t("GOT_NEW_STAMP") );
      }
    }

  return me;
}

function* tokenWatcher() {
  yield takeLatest(GOT_TOKEN, _getMe);
}

function* _getMe({
  token,
  locale = 'en',
  skip = false
}) {
  global.log("GOT TOKEN, check now");
  const uuid = yield select(getUUID);
  const trusted = yield select(getTrust);

  try {
    const response = yield call(getMe, {
      token,
      location_code: null,
      location: null,
      device: {
        uuid,
        os: osName + " " + osVersion,
        browser: browserName + " " + browserVersion,
        locale,
        trusted,
        version: '4.0.x'
      },
      includes: "add_by_id,weather,paragraph",
      trusted
    });

    const mappedMe = yield call(mapMe, { me: response });
    global.log('mappedMe', mappedMe)

    yield put({
      type: GET_ME_SUCCESS,
      response: { ...mappedMe, token }
    });

    if (!skip) {
      yield put({ type: GET_UNREAD, token });
      yield put({ type: GET_FRIENDS, token });
      yield put({ type: INIT_APP_COMPLETE });
      global.log('INIT_APP_COMPLETE')
      
      yield put({ type: CHECK_INCOMING, token });
      const { bookmarks, fav } = yield call(getStampData, { token });
      yield put({ type: UPDATE_BOOKMARKS_SUCCESS, bookmarks });
      yield put({ type: UPDATE_FAV_SUCCESS, fav });

      const results = yield call(checkStore, { token });

      global.log('SYNC_STORE results', results);
      const unlocked = yield select(reUnlocked);
      const oldStore = yield select(getStore)

      if (results.status === 2) {
        yield put({ type: REFRESH_COINS, coins: results.coins });
        if (results.coins >= 500) {
          try {
            if (unlocked.indexOf('first-bucket-of-gold') < 0) {
              const result = yield call(checkAchievement, {
                token,
                stamp: 'first-bucket-of-gold',
              });
              global.log('checkAchievement', result);
              if (!!result.achievement) yield put({ type: REFRESH_STAMPS, from: 'REFRESH_MY_DATA' });
            }
          } catch (error) {
            global.log('Unlock achievement error', error);
          }
        }
      }

      const storeNew = (results.latest > oldStore.latest || oldStore.latest===null)
      if(!!storeNew){
        yield put({ type: SYNC_STORE_COMPLETE, store: {
          ...results,
          new: true
        }});
        return true;
      }
      const limitedNew = (!!results.limited && results.limited.length>0)

      if(!!limitedNew){
        const newLimited = results.limited.join(',')
        global.log('SYNC_STORE newLimited', newLimited)
        const oldLimited = !!oldStore.limited ? oldStore.limited.join(',') : ''
        global.log('SYNC_STORE oldLimited', oldLimited)

        if(newLimited!==oldLimited){
          global.log('SYNC_STORE limited updated, check if user unlocked all');

          const anyNew = _.filter(results.limited, l => {
            return unlocked.indexOf(l)<0
          })
          global.log('SYNC_STORE anyNew?' , anyNew)

          if(anyNew.length>0){
            yield put({ type: SYNC_STORE_COMPLETE, store: {
              ...results,
              new: true
            }});

            return true;
          }
        }
      }
      
      yield put({ type: SYNC_STORE_COMPLETE, store: {
        ...results,
        new: oldStore.new
      }});

      const collection = yield call(syncCollection, { token });
      yield put({ type: GET_COLLECTION_SUCCESS, collection });
    }
  } catch (error) {
    global.log('got token error', error)
    if(!error.error){
      //assume 403
      yield put({ type: SHOW_ERROR, error: { error: "ERROR403_ACCESS_DENIED" } });
      return false;
    }
    if(error.error==='token_expired' || error.error==='invalid_user' ){
      const uid = yield select(getMyID);
      const trusted = yield select(getTrust);

      if(!trusted){
        yield put({ type: SHOW_ERROR, error: { error: "token_expired" } });
        yield put({ type: LOGOUT });
      }else if (!!uid) {
        try{
          global.log("refreshWebToken", { token, uuid, uid });
          const refreshed = yield call(refreshWebToken, { token, uuid, uid });

          global.log("refreshed", refreshed);
          yield put({ type: GOT_TOKEN, token: refreshed.token });

        }catch(error){
          yield put({ type: SHOW_ERROR, error: { error: "token_expired" } });
          yield put({ type: LOGOUT });
        }
      }else{
        yield put({ type: SHOW_ERROR, error: { error: "token_expired" } });
        yield put({ type: LOGOUT });
      }
    }else if(error.error==='token_invalid'){
      yield put({ type: SHOW_ERROR, error: { error: "token_expired" } });
      yield put({ type: LOGOUT });
    }else if(error.error){
      yield put({ type: SHOW_ERROR, error });
    }else{
      yield put({ type: SHOW_ERROR, error: { error: "TOKEN_EXPIRED" } });
    }
  }
}

function* refreshStampsWatcher() {
  while (true) {
    const { items = null, newStamps = [], isSet = false, skipRefresh = false } = yield take(
      REFRESH_STAMPS
    );

    if(skipRefresh){
      yield put({
        type: REFRESH_STAMPS_SUCCESS,
        items,
        newStamps,
        isSet
      });
    }else{
      try {
        var _items = items;
        var _newStamps = newStamps;

        if (!items) {
          const token = yield select(getToken);
          const result = yield call(getStamps, { token });
          _items = result.items;
          _newStamps = result.newStamps;
        }

        yield put({
          type: REFRESH_STAMPS_SUCCESS,
          items: _items,
          newStamps: _newStamps,
          isSet
        });
      } catch (error) {
        global.log(error);
        yield put({
          type: ME_ERROR,
          error,
          action: REFRESH_STAMPS,
          payload: { items, newStamps, isSet }
        });
      }
    }
  }
}

function* logoutWatcher() {
  while (true) {
    yield take(LOGOUT);

    const token = yield select(getToken);
    const uuid = yield select(getUUID);

    try {
      if (!!token) {
        yield call(clearToken, { token, uuid });
        toast.info(I18n.t("LOGGED_OUT"), {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          closeOnClick: true,
        });
      }
    } catch (e) {
      global.log(e);
    }

    yield put({ type: LOGOUT_COMPLETE });
  }
}

function* checkDOB({ dob, pref, zodiac, token }) {
  global.log("Check DOB");
  try {
    if (moment(dob).format("MM-DD") === moment().format("MM-DD")) {
      if (!pref["dob-" + moment().format("YYYY")]) {
        yield call(unlockStamp, {
          token,
          stamp: "happy-birthday-" + moment().format("YYYY")
        });

        yield put({
          type: CHECK_DOB,
          zodiac: !!zodiac ? zodiac : false,
          isBirthday: true,
          birthdayStamp: "happy-birthday-" + moment().format("YYYY")
        });

        pref["dob-" + moment().format("YYYY")] = true;
        yield call(updateMe, { token, updateFields: { pref } });
      } else {
        yield put({
          type: CHECK_DOB,
          zodiac: !!zodiac ? zodiac : false,
          isBirthday: true,
          birthdayStamp: false
        });
      }
    } else if (!!zodiac) {
      yield put({
        type: CHECK_DOB,
        zodiac,
        isBirthday: false,
        birthdayStamp: false
      });
    }
  } catch (error) {
    global.log(error);
  }
}

function* updatePrefWatcher() {
  while (true) {
    const { token, updateFields } = yield take(UPDATE_PREF);
    global.log(updateFields);

    try {
      const result = yield call(updatePref, { token, updateFields });
      global.log(result);

      yield put({ type: UPDATE_PREF_SUCCESS, result });
      yield put({ type: RESET_ERROR });
    } catch (error) {
      yield put({
        type: ME_ERROR,
        error,
        action: UPDATE_PREF,
        payload: { token, updateFields }
      });
    }
  }
}

function* updateMeWatcher() {
  while (true) {
    const { token, updateFields, skip } = yield take(UPDATE_ME);
    global.log(updateFields);

    try {
      const updateAgeFilter = updateFields.hasOwnProperty("target_age_from")
        ? updateFields.target_age_from === null
          ? {
              target_age_from: -1
            }
          : {}
        : {};

      const newMe = yield call(updateMe, {
        token,
        updateFields: {
          ...updateFields,
          ...updateAgeFilter
        }
      });

      global.log(newMe);

      if (!!updateFields.dob) {
        const pref = JSON.parse(newMe.pref);
        yield call(checkDOB, {
          dob: updateFields.dob,
          zodiac: newMe.zodiac,
          pref,
          token
        });
      }

      const photoQuota = yield select(getPhotoQuota);
      const mappedMe = yield call(mapMe, {
        me: {
          ...updateFields,
          ...newMe
        },
        photoQuota
      });

      global.log('update success', mappedMe)

      yield put({ type: UPDATE_ME_SUCCESS, updateFields: mappedMe, skip });

      tracking.event("Profile", "update");
      yield put({ type: RESET_ERROR });
    } catch (error) {
      yield put({
        type: ME_ERROR,
        error,
        action: UPDATE_ME,
        payload: { token, updateFields, skip }
      });
    }
  }
}

function* langWatcher() {
  while (true) {
    const { token, action, langId, slug, level } = yield take(LANG_INIT);
    let response;

    try {
      if (action === "ADD") {
        response = yield call(addLang, { token, slug, level });
        yield put({ type: LANG_ADD_SUCCESS, lang: response });
        tracking.event("Language", "add", slug, level);
      } else if (action === "UPDATE") {
        response = yield call(updateLang, { token, langId, slug, level });
        yield put({ type: LANG_UPDATE_SUCCESS, lang: response });
        tracking.event("Language", "update", slug, level);
      } else if (action === "REMOVE") {
        response = yield call(removeLang, { token, langId });
        yield put({ type: LANG_REMOVE_SUCCESS, langId: langId });

        tracking.event("Language", "remove", slug, level);
      }
      yield put({ type: SAVE_EXPLORE_FILTERS });
      yield put({ type: RESET_ERROR });
    } catch (error) {
      yield put({
        type: ME_ERROR,
        error,
        action: LANG_INIT,
        payload: { token, action, langId, slug, level }
      });
    }
  }
}
function* excludeTagsWatcher() {
  while (true) {
    const { token, tags } = yield take(SYNC_EXCLUDE_TOPICS);

    try {
      const response = yield call(syncExcludeTopics, { token, tags });
      global.log(response);

      yield put({
        type: SYNC_EXCLUDE_TOPICS_SUCCESS,
        excluded_tags: response.excluded_tags
      });

      tracking.event("exclude_tags", "sync");
      yield put({ type: SAVE_EXPLORE_FILTERS });
      yield put({ type: RESET_ERROR });
    } catch (error) {
      yield put({
        type: ME_ERROR,
        error,
        action: SYNC_EXCLUDE_TOPICS,
        payload: { token, tags }
      });
    }
  }
}

function* updateBookmarkWatcher() {
  while (true) {
    const { token, bookmarks = [] } = yield take(UPDATE_BOOKMARKS);

    try {
      const response = yield call(syncStampBookmark, { token, bookmarks });
      global.log("syncStampBookmark", response);

      yield put({ type: UPDATE_BOOKMARKS_SUCCESS, bookmarks });
      yield put({ type: RESET_ERROR });
    } catch (error) {
      yield put({
        type: ME_ERROR,
        error,
        action: UPDATE_BOOKMARKS,
        payload: { token, bookmarks }
      });
    }
  }
}

function* updateFavWatcher() {
  while (true) {
    const { token, fav = [] } = yield take(UPDATE_FAV);

    try {
      const response = yield call(syncStampFav, { token, fav });
      global.log("syncStampFav", response);

      yield put({ type: UPDATE_FAV_SUCCESS, fav });
      yield put({ type: RESET_ERROR });
    } catch (error) {
      yield put({
        type: ME_ERROR,
        error,
        action: UPDATE_FAV,
        payload: { token, fav }
      });
    }
  }
}

const _meSaga = [
  tokenWatcher,
  refreshStampsWatcher,
  logoutWatcher,
  updatePrefWatcher,
  updateMeWatcher,
  excludeTagsWatcher,
  updateBookmarkWatcher,
  updateFavWatcher,
  langWatcher
]

export default _meSaga
