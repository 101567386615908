import React, { Component } from "react";
import { connect } from "react-redux";
import { reProfileMe } from "../selectors";
import I18n from "../I18n";
import { getData, checkoutSession } from "../api/payment.api";
import { toast } from "react-toastify";
import tracking from "../lib/tracking";
import _ from "lodash";
import { Link } from "react-router-dom";
import Emoji from "../components/Emoji";
import moment from "moment";

class Upgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coinsdata: null,
      save: true,
      loading: false,
      stripe: null,
      ready: false,
      plans: ["annual", "six-month", "monthly"],
      annual: {
        title: "YEARLY_PLAN",
        months: 12,
        price: "...",
        total_discounted: null,
        discount: null,
      },
      "six-month": {
        title: "HALF_YEARLY_PLAN",
        months: 6,
        price: "...",
        total_discounted: null,
        discount: null,
      },
      monthly: {
        title: "MONTHLY_PLAN",
        months: 1,
        price: "...",
        total_discounted: null,
        discount: null,
      },
      selectedPlan: "annual",
      currency: "...",
      discount: true,
      alert: null,
    };
  }

  componentDidMount() {
    this.setup();
  }

  changePlan = (plan) => {
    if (this.state.loading) return false;

    this.setState({
      selectedPlan: plan,
    });
    tracking.event("changePlan_" + plan);
  };

  _continue = async () => {
    this.setState({ loading: true });

    try {
      const _checkout = await checkoutSession({
        token: this.props.me.token,
        payload: {
          product_id: this.state[this.state.selectedPlan].id,
          currency: this.state.currency,
          device_id: this.props.uuid,
          locale: this.props.locale,
          mode: "subscription",
          promo: !!this.state.discount_type ? this.state.discount_type : null,
        },
      });
      global.log("_checkout", _checkout);

      if (_checkout.url) {
        window.top.location.href = _checkout.url;
        return true;
      }
    } catch (error) {
      global.log("stripe error", error);
      this.props.showError({
        message: "UNABLE_TO_REFRESH_DATA",
      });
    }

    this.setState({ loading: false });
  };

  _back = () => {
    this.setState({
      next: false,
    });
  };

  priceList = (plan) => {
    const isSelected = plan === this.state.selectedPlan;
    const _plan = this.state[plan];
    if (!_plan.amount) return null;

    if (!isSelected && !!this.state.next) return false;
    const total = this.state["monthly"].amount * _plan.recurring;
    const discount = Math.round(((total - _plan.amount) / total) * 100);

    return (
      <div
        className={
          !!isSelected
            ? plan === "annual" || plan === "six-month"
              ? "card mb-2 mt-3 price-selected link"
              : "card mb-2 price-selected link"
            : "card my-2 link"
        }
        key={_plan.title}
        onClick={() => {
          this.changePlan(plan);
        }}
      >
        <div className={"card-body"}>
          {_plan.recurring &&
          (plan === "annual" || plan === "six-month") &&
          !!isSelected ? (
            <div className="position-absolute discount-badge badge badge-xs badge-danger">
              {I18n.t("SAVE_DISCOUNT", { discount })}

              {plan === "annual" && (
                <span>
                  {" + " + I18n.t("Bonus") + " "}
                  <i className="icon-hot" />
                </span>
              )}
            </div>
          ) : (
            !_plan.recurring &&
            plan === "annual" && (
              <div className="position-absolute discount-badge badge badge-xs badge-danger">
                {I18n.t("BEST_VALUE")}
              </div>
            )
          )}
          <div className="d-flex align-items-center">
            <div>
              <h3 className="mb-0">{I18n.t(_plan.title)}</h3>
              {_plan.recurring &&
                (plan === "annual" || plan === "six-month") &&
                !!isSelected && (
                  <div className="h5 mt-1 mb-0">
                    {
                      <del className="pr-2 font-weight-normal">
                        {_plan.symbol}
                        {I18n.toNumber(total, {
                          precision: _plan.no_decimal ? 0 : 2,
                        })}
                      </del>
                    }
                    {_plan.symbol}
                    {I18n.toNumber(_plan.amount, {
                      precision: _plan.no_decimal ? 0 : 2,
                    })}
                  </div>
                )}
            </div>
            <div className="text-right flex-fill">
              {!_plan.recurring ? (
                <h4 className="mb-0">
                  {_plan.symbol}
                  {I18n.toNumber(_plan.amount, {
                    precision: _plan.no_decimal ? 0 : 2,
                  })}
                </h4>
              ) : (
                <h4 className="mb-0">
                  {_plan.symbol}
                  {I18n.toNumber(_plan.amount / _plan.recurring, {
                    precision: _plan.no_decimal ? 0 : 2,
                  })}
                  <span
                    className="font-weight-normal"
                    style={{ fontSize: ".8em" }}
                  >
                    {" "}
                    {I18n.t("PER_MONTH")}
                  </span>
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  setup = async () => {
    const { token } = this.props.me;
    const result = await getData({
      token,
    });
    global.log("getData", result);

    const discount_expire_at = !!result.discount_expire_at
      ? moment.utc(result.discount_expire_at).diff(moment(), "days")
      : null;
    const coinsValue = result.coinsdata
      ? result.coinsdata[result.coinsdata.length - 1]
      : {};

    this.setState({
      coinsValue:
        coinsValue.slug === "coins_800"
          ? coinsValue.symbol + coinsValue.amount
          : "US$11.99",
      ready: true,
      currency: result.currency,
      tax: result.tax,
      location_code: result.location_code,
      discount: result.discount,
      discount_type: result.discount_type,
      discount_expire_at,
      ..._.keyBy(result.plans, "slug"),
    });
  };

  lockProductList = () => {
    this.setState({
      locked: true,
    });
  };

  unlockProduct = () => {
    this.setState({
      locked: false,
    });
  };

  onLoad = () => {
    if (!!this.state.loading) return false;
    this.setState({
      loading: true,
    });
  };

  onCancel = () => {
    this.setState({
      loading: false,
    });
  };

  onError = (error) => {
    this.setState({
      loading: false,
    });
    if (error)
      toast.error(I18n.t(error.error), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
        ...error,
      });
    else
      toast.error(I18n.t("INTERNAL_ERROR"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
      });
  };

  render() {
    const { selectedPlan, ready, plans } = this.state;

    if (!ready) {
      return (
        <div className="w-100 store-wrapper text-center pt-5">
          <small>
            <span
              className="spinner-grow spinner-grow-sm mr-2 span-warning"
              role="status"
              aria-hidden="true"
            />
            {I18n.t("LOADING")}
          </small>
        </div>
      );
    }
    return (
      <div className="container plus no-min-width">
        <div className="p-4 position-relative">
          {!this.state.locked && (
            <div className="row">
              <div className="col-12 col-xl-6">
                <div className="sticky-top">
                  <div className="plus-banner">
                    <img
                      src="https://promo.slowly.app/banner_plus-upgrade.png"
                      alt="Slowly Plus"
                      className="plus-banner"
                    />
                    <div
                      className="position-absolute plus-text"
                      style={{ top: -40 }}
                    />
                    <div className="p-4 mt-5 stripe-credit">
                      <a
                        className="no-underline span-lighter mb-3"
                        alt={I18n.t("Secure payment powered by Stripe")}
                        href="https://www.stripe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <small>
                          <i className="icon-lock mr-1" />{" "}
                          {I18n.t("Secure payment powered by Stripe")}{" "}
                        </small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col bg-stable main-col">
                {!!this.state.next && (
                  <button
                    type="button"
                    onClick={this._back}
                    className="btn btn-default btn-sm mb-2"
                  >
                    <i className="icon-chevron-left mr-1" />
                    {I18n.t("BACK")}
                  </button>
                )}
                {plans.map(this.priceList)}
                <div className="p-2 d-flex small">
                  <div className="text-light">
                    {I18n.t("CURRENCY_WITH_CODE", {
                      code: this.state.currency,
                    })}
                  </div>
                  <div className="flex-fill text-right mr-1">
                    {this.state.discount > 0 && (
                      <div className="text-danger font-weight-bold h6">
                        <i className="icon-gift mr-1" />
                        {this.state.discount +
                          "% OFF (–" +
                          this.state[selectedPlan].symbol +
                          Math.round(
                            this.state[selectedPlan].amount *
                              (this.state.discount / 100) *
                              100
                          ) /
                            100 +
                          ")"}
                        {this.state.discount_type === "buymeacoffee" && (
                          <span>{"( Buymeacoffee )"}</span>
                        )}
                        {this.state.discount_expire_at !== null &&
                          !this.state.next && (
                            <div className="mt-1 ml-2 text-danger small">
                              <Emoji code="⏰" />{" "}
                              {this.state.discount_expire_at > 1
                                ? I18n.t("EXPIRES_IN_DAYS", {
                                    days: this.state.discount_expire_at,
                                  })
                                : I18n.t("LAST_DAY")}
                            </div>
                          )}
                      </div>
                    )}
                    {!!this.state.tax && (
                      <span className="mb-1">
                        {!!this.state.next
                          ? " + " +
                            this.state.tax.type +
                            this.state[selectedPlan].symbol +
                            this.state[selectedPlan].tax
                          : " + " +
                            this.state.tax.type +
                            " " +
                            this.state.tax.rate +
                            "%"}
                        <span className="text-light ml-2">
                          {"( " + I18n.country(this.state.location_code) + " )"}
                        </span>
                      </span>
                    )}
                    {!!this.state.next && (
                      <h5>
                        <span className="mr-2">{I18n.t("Total")}</span>
                        {this.state[selectedPlan].symbol}
                        {!!this.state[selectedPlan].amount_total
                          ? this.state[selectedPlan].amount_total
                          : this.state[selectedPlan].amount_after_tax}
                      </h5>
                    )}
                  </div>
                </div>
                {this.state.discount_type === "buymeacoffee" && (
                  <div className="alert alert-warning" role="alert">
                    <Emoji code="☕️" size={16} />{" "}
                    {I18n.t(
                      "To show our gratitude to your Coffee support, you will get an extra 20% discount for the first year of Slowly Plus subscription."
                    )}
                  </div>
                )}
                {this.state.discount_type === "twoyears" && (
                  <div className="alert alert-warning" role="alert">
                    <Emoji code="🎉" size={16} />
                    <strong className="mx-2">
                      {I18n.t(
                        "Thanks for joining Slowly for more than two years!"
                      )}
                    </strong>
                    {I18n.t(
                      "To show our gratitude, you will get an extra 20% discount for the first year of Slowly Plus subscription."
                    )}
                  </div>
                )}

                <hr className="mb-4" />
                {!this.state.next && (
                  <>
                    {/* Privileges */}
                    <div className="d-flex w-100 my-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt1.png")}
                          alt={I18n.t("Unlimited Advanced Filters")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("Unlimited Advanced Filters")}
                        </h5>
                        {I18n.t("By a Specific City, Exact Topics and More")}
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt-coins.png")}
                          alt={I18n.t("MONTHLY_COINS")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("MONTHLY_COINS")}</h5>
                        {I18n.t("MONTHLY_COINS_DESC", { coins: 100 })}
                      </div>
                    </div>

                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-cool.png")}
                          alt={I18n.t("Ad-Free")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("Ad-Free")}</h5>
                        {I18n.t("AD_FREE_DESC")}
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt2.png")}
                          alt={I18n.t("Double Up Everything")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("Double Up Everything")}
                        </h5>
                        {I18n.t("Friend Limit, Media Quota, Everything")}
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt4.png")}
                          alt={I18n.t("Enhanced Media Sharing")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">
                          {I18n.t("Enhanced Media Sharing")}
                        </h5>
                        {I18n.t("High-res Photos and Extended Audio Notes")}
                      </div>
                    </div>
                    <div className="d-flex w-100 mb-3">
                      <div className="px-2">
                        <img
                          style={{ width: 26, height: 26 }}
                          src={require("../assets/images/plus-pt-gift.png")}
                          alt={I18n.t("Plus Privileges")}
                        />
                      </div>
                      <div className="flex-fill">
                        <h5 className="mb-0">{I18n.t("Plus Privileges")}</h5>
                        {I18n.t("Plus-only Avatar Items and Achievement Stamp")}
                      </div>
                    </div>

                    <div
                      className="mt-4"
                      style={{
                        opacity: selectedPlan === "annual" ? 1 : 0.3,
                        marginBottom: 100,
                      }}
                    >
                      <div
                        className="badge badge-danger p-1 px-2 mb-4"
                        style={{ fontSize: "1em" }}
                      >
                        <Emoji code="✨" size={16} />{" "}
                        {I18n.t("YEAR_PLAN_BONUS_FEATURES")}
                      </div>
                      <div className="d-flex w-100 mb-3">
                        <div className="px-2">
                          <img
                            style={{ width: 26, height: 26 }}
                            src={require("../assets/images/plus-pt3.png")}
                            alt={I18n.t("Unlimited Styles")}
                          />
                        </div>
                        <div className="flex-fill">
                          <h5 className="mb-0">{I18n.t("Unlimited Styles")}</h5>
                          {I18n.t("Unlock All Avatar Items for Free")}
                        </div>
                      </div>
                      <div className="d-flex w-100 mb-3">
                        <div className="px-2">
                          <img
                            style={{ width: 26, height: 26 }}
                            src={require("../assets/images/plus-pt-coins.png")}
                            alt={I18n.t("Get 800 Coins Upon Registration")}
                          />
                        </div>
                        <div className="flex-fill">
                          <h5 className="mb-0">
                            {I18n.t("Get 800 Coins Upon Registration")}
                          </h5>
                          {I18n.t("800_COINS_WORTH", {
                            price: this.state.coinsValue + "",
                          })}
                        </div>
                      </div>
                      <div className="d-flex w-100 mb-3">
                        <div className="px-2">
                          <img
                            style={{ width: 26, height: 26 }}
                            src={require("../assets/images/plus-pt-bonus.png")}
                            alt={I18n.t("Get 1 Extra Achievement Stamp")}
                          />
                        </div>
                        <div className="flex-fill">
                          <h5 className="mb-0">
                            {I18n.t("Get 1 Extra Achievement Stamp")}
                          </h5>
                          {I18n.t("Never Stop Writing")}
                        </div>
                      </div>
                    </div>
                    <div className="position-fixed w-100 bottom-action-bar bg-stable">
                      <div className="container no-min-width">
                        <div className="row">
                          <div className="col-6 d-none d-xl-block"></div>
                          {!(
                            this.state.location_code === "IN" ||
                            this.state.location_code === "RU"
                          ) ? (
                            <div className="col main-col">
                              <hr className="mt-0 mb-3" />
                              <button
                                type="button"
                                className="btn btn-primary btn-block py-2 shadow-lg mb-3"
                                onClick={this._continue}
                                disabled={this.state.loading}
                              >
                                {I18n.t("CONTINUE")}
                              </button>
                            </div>
                          ) : (
                            <div className="col">
                              <hr className="mt-0 mb-0" />
                              <div className="p-3 text-center">
                                <i className="icon-info mr-2 text-danger" />
                                Please purchase via our mobile app.
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale,
    uuid: state.slowly.uuid,
  };
};

const refreshCoins = function refreshCoins(coins, from = "upgrade") {
  return {
    type: "REFRESH_COINS",
    coins,
    from,
  };
};

const showError = function showError(error) {
  return {
    type: "SHOW_ERROR",
    error,
  };
};

export default connect(mapStateToProps, {
  refreshCoins,
  showError,
})(Upgrade);
