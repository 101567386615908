import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Loader from "../components/Loader";
import { getData, getDataPublic } from "../api/payment.api";
import { eventLog } from "../api/slowly.api";
import Login from "./Login";
import moment from "moment";
// import tracking from "../lib/tracking";
import _ from "lodash";
import StripeForm from "../components/StripeForm";
import { reProfileMe } from "../selectors";
import { toast } from "react-toastify";
import { REDIRECT_URL } from "../config/ApiConfig";
import Hashids from "hashids";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

const __DEV__ = REDIRECT_URL !== "https://web.slowly.app"

class Promo extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: false,
      showLogin: false,
      ready: false,
      coinsValue: "...",
      step: 1,
    };
  }

  componentDidMount() {
    this.setup();
  }

  setup = async () => {
    const {
      match: { params },
      me,
    } = this.props;

    const parsed = !!window.location
      ? queryString.parse(window.location.search)
      : {};
    // global.log(parsed);

    if (!!me && !!params.returnedhash && parsed.redirect_status !== "failed") {
      global.log("returnedhash: " + params.returnedhash);
      const hashids = new Hashids(
        "+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B",
        30
      );

      const unhashed = hashids.decode(params.returnedhash);
      global.log("decoded hash", { unhashed, me });

      this.setState({
        redirect_status: parsed.redirect_status
      })

      if (unhashed[0] === me.id) {
        global.log("show finished");

        this.onUpgraded()
        return true;
      }
    }

    const token = !!me ? me.token : null;
    const _getData = !!token ? getData : getDataPublic;

    try {
      const result = await _getData({
        token,
        promo: params.code,
      });
      global.log("getData", result);

      if (!result.discount) {
        this.invalidCode();
        return false;
      }

      const now = moment();
      if (now > moment(result.discount_expire_at).add(12, "hours") && !__DEV__) {
        toast.info("Oops! Offer expired.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          closeOnClick: true,
        });
        this.setState({
          expired: true,
        });

        return true;
      }

      const coinsValue = result.coinsdata
        ? result.coinsdata[result.coinsdata.length - 1]
        : {};

      this.setState({
        coinsValue:
          coinsValue.slug === "coins_800"
            ? coinsValue.symbol + coinsValue.amount
            : "US$11.99",
        cards: _.filter(
          result.cards,
          (c) => !!c.card_brand && !!c.card_last_four
        ),
        ready: true,
        currency: result.currency,
        tax: result.tax,
        location_code: result.location_code,
        discount: result.discount,
        discount_type: result.discount_type,
        discount_expire_at: result.discount_expire_at,
        ..._.keyBy(result.plans, "slug"),
        payment_methods: [...result.extra_payment_methods],
        coupon: params.code,
      });
    } catch (error) {
      global.log("getData error", error);
      this.invalidCode();
      return false;
    }
  };

  invalidCode = () => {
    this.setState({ invalidPromo: true });
    return true;
  };

  onLoad = () => {
    if (!!this.state.loading) return false;
    this.setState({
      loading: true,
    });
  };

  onError = (error) => {
    this.setState({
      loading: false,
    });
    if (error)
      toast.error(I18n.t(error.error), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true,
        ...error,
      });
    else
      toast.error(I18n.t('INTERNAL_ERROR'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        closeOnClick: true
      });
  };

  onCancel = () => {
    this.setState({
      loading: false,
    });
  };

  hideAllModals = () => {
    this.setState({
      showLogin: false,
      showFeatures: false,
    });
  };

  claimOffer = () => {
    const loggedIn = !!this.props.me && this.props.me.token;

    if (loggedIn) {
      this.setState({
        showFeatures: false,
        showLogin: false,
        step: 2,
        loading: false,
      });

      this.logNow("interested");
    } else {
      this.setState({
        showFeatures: false,
        showLogin: true,
      });
    }

    window.scrollTo(0, 0);
  };

  onSuccessLogin = () => {
    this.setState({
      step: 2,
    });
    window.scrollTo(0, 0);

    this.logNow("interested");
  };

  logNow = async (event) => {
    try {
      const result = await eventLog({
        token: this.props.me.token,
        payload: { promotion: this.state.discount_type, event },
      });
      global.log("event Logged", result);
    } catch (error) {
      global.log("eventLog error", error);
    }
  };

  _back = () => {
    this.setState({
      step: 1,
    });
  };

  onUpgraded = () => {
    this.setState({
      ready: true,
      step: 3,
      showLogin: false,
      showFeatures: false,
    });
    window.scrollTo(0, 0);
    this.logNow('upgraded')
  };

  render() {
    const { me } = this.props;

    const _plan = this.state.annual;
    const loggedIn = !!me && !!me.token && !!me.id;
    const upgraded = loggedIn && me.role >= 20;

    if (this.state.expired || this.state.invalidPromo)
      return <Redirect to="/upgrade" />;

    if (upgraded || this.state.step === 3) {
      return (
        <div className={"container enable-sm campaign step" + this.state.step}>
          <div className="row main-row mb-3">
            <div className="col-12 text-center">
              <img
                src="https://promo.slowly.app/promo_2024_upgraded.png"
                style={{ width: 680, maxWidth: "100%" }}
                className="mt-5"
              />
              <h3 className="mt-3 mb-2 text-darker">
                {I18n.t("Upgraded to SLOWLY PLUS")}
              </h3>
              <div>
                { I18n.t("PLUS_ACTIVATED") }
              </div>

              <div
                className="btn btn-secondary badge-pill btn-md px-5 mt-4 mb-5 link"
                onClick={() => {
                  if (!!isMobile)
                    window.top.location = "https://slowly.app/download";
                  else 
                    window.top.location.href = REDIRECT_URL + "/home";
                }}
              >
                <strong>
                  {I18n.t("REG_COMPLETE_ACTION")}
                </strong>
              </div>

              <hr />
              <div className="text-center mb-5">
                <div className="text-lighter ">
                  <a
                    href={"https://slowly.app"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    <i className="icon-slowly mr-1" />
                    Slowly
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://slowly.app/terms/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Terms of Service
                  </a>
                  &nbsp; · &nbsp;
                  <a
                    href={"https://slowly.app/privacy/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-light"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (!this.state.ready || !this.state.annual) return <Loader />;

    return (
      <div className={"container enable-sm campaign step" + this.state.step}>
        <div className="row main-row mb-3">
          <div className="col-lg-12 col-xl-6 art-col p-0">
            <div className="keyart-2024">
              <div className="img" />
            </div>
          </div>
          {this.state.step === 1 || !loggedIn ? (
            <div className="col-lg-12 col-xl-6 px-3 d-flex align-items-center text-col">
              <div style={{ borderWidth: 1, borderColor: "blue" }}>
                <div className="text-darker">Limited-Time Offer</div>
                <h1 className="text-darker title mt-1 mb-0">
                  Get 40% Off Slowly Plus!
                </h1>
                <h1 className="text-sale">
                  <span className="crossed mr-2">
                    {_plan.symbol}
                    {I18n.toNumber(_plan.amount, {
                      precision: _plan.no_decimal ? 0 : 2,
                    })}
                  </span>
                  {_plan.symbol}
                  {I18n.toNumber(
                    _plan.amount * (1 - this.state.discount / 100),
                    { precision: _plan.no_decimal ? 0 : 2 }
                  )}
                </h1>
                <p>
                  Build Meaningful Connections in 2024 with Slowly Plus.&nbsp;
                </p>
                {/* <p>Some good reasons to upgrade:</p> */}

                <div className="d-flex w-100 my-3 p strong">
                  <div className="pr-2">
                    <i className="icon-checkmark text-calm" />
                  </div>
                  <div className="flex-fill">Enjoy an ad-free experience</div>
                </div>
                <div className="d-flex w-100 my-3  p strong">
                  <div className="pr-2">
                    <i className="icon-checkmark text-calm" />
                  </div>
                  <div className="flex-fill">
                    Utilise advanced search filters
                  </div>
                </div>
                <div className="d-flex w-100 my-3  p strong">
                  <div className="pr-2">
                    <i className="icon-checkmark text-calm" />
                  </div>
                  <div className="flex-fill">
                    Receive 900 coins immediately, plus 100 monthly coins
                  </div>
                </div>
                <div className="d-flex w-100 my-3  p strong">
                  <div className="pr-2">
                    <i className="icon-checkmark text-calm" />
                  </div>
                  <div className="flex-fill">
                    Support a team that values deep, thoughtful communication
                  </div>
                </div>
                <div
                  className="d-flex w-100 my-3  p strong link"
                  onClick={() => {
                    this.setState({
                      showFeatures: true,
                    });
                  }}
                >
                  <u>...and many more!</u>
                </div>
                <div className="d-sm-block d-none">
                  <div
                    className="btn btn-primary btn-lg mt-3 link"
                    onClick={this.claimOffer}
                  >
                    <strong>Get the Offer</strong>
                  </div>
                  {!!this.state.discount_expire_at && (
                    <div className="mt-3 mb-3 text-light">
                      *Available until{" "}
                      {moment(this.state.discount_expire_at).format("LL")}
                    </div>
                  )}
                </div>
                <div className="d-sm-none mt-5" />

                <div className="fixed-bottom btn-spill d-sm-none border-top p-2 bg-white">
                  <div
                    className="btn btn-primary badge-pill btn-lg btn-block link"
                    onClick={this.claimOffer}
                  >
                    <strong className="h6">Get the Offer</strong>
                  </div>
                  {!!this.state.discount_expire_at && (
                    <div className="mt-2 mb-1 text-lighter small text-center">
                      *Available until{" "}
                      {moment(this.state.discount_expire_at).format("LL")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-12 col-xl-6 px-3 d-flex align-items-center text-col plus">
              <div className="form-holder">
                <button
                  type="button"
                  onClick={this._back}
                  className="btn btn-default btn-sm my-2"
                >
                  <i className="icon-chevron-left mr-1" />
                  {I18n.t("BACK")}
                </button>
                <div className="card mt-3 mb-1 price-selected link">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <h3 className="mb-0">Slowly Plus</h3>
                        <div>
                          {I18n.t(_plan.title) +
                            ", " +
                            this.state.discount +
                            "% off"}
                        </div>
                      </div>
                      <div className="text-right flex-fill">
                        <h4 className="text-sale">
                          <span className="crossed text-lighter small">
                            {_plan.symbol}
                            {I18n.toNumber(_plan.amount, {
                              precision: _plan.no_decimal ? 0 : 2,
                            })}
                          </span>
                          <br />
                          {_plan.symbol}
                          {I18n.toNumber(
                            _plan.amount * (1 - this.state.discount / 100),
                            { precision: _plan.no_decimal ? 0 : 2 }
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-light text-right small mb-2 mx-1">
                  {I18n.t("CURRENCY_WITH_CODE", {
                    code: this.state.currency,
                  })}
                </div>

                <StripeForm
                  currency={this.state.currency}
                  product={_plan}
                  plan={_plan.sku}
                  loading={this.state.loading}
                  onLoad={this.onLoad}
                  onCancel={this.onCancel}
                  onError={this.onError}
                  cards={this.state.cards}
                  payment_methods={this.state.payment_methods}
                  location_code={this.state.location_code}
                  discount_type={this.state.discount_type}
                  // hideRemarks={true}
                  onUpgraded={this.onUpgraded}
                  returnURL={
                    REDIRECT_URL + "/promo/" + this.state.discount_type + "/"
                  }
                />
              </div>
            </div>
          )}
        </div>

        <div
          className="text-light text-center mb-5 mx-auto"
          style={{ maxWidth: 1200 }}
        >
          This offer is applicable only to 12-month subscriptions purchased via
          this promotional web page. The discount applies to your first payment
          only. Your subscription will automatically renew after 12 months at
          the standard rate, unless you alter your renewal settings.
        </div>

        <hr />
        <div className="text-center mb-5">
          {!loggedIn && (
            <div className="text-lighter mb-2">
              Don't have an account? &nbsp;
              <a href={"https://slowly.app/download/"}>
                <u>Join Now</u>
              </a>
            </div>
          )}

          <div className="text-lighter ">
            <a
              href={"https://slowly.app"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <i className="icon-slowly mr-1" />
              Slowly
            </a>
            &nbsp; · &nbsp;
            <a
              href={"https://slowly.app/terms/"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              Terms of Service
            </a>
            &nbsp; · &nbsp;
            <a
              href={"https://slowly.app/privacy/"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              Privacy Policy
            </a>
          </div>

          <div className="d-sm-none" style={{ height: 50 }} />
        </div>
        <Modal
          show={this.state.showFeatures}
          onHide={this.hideAllModals}
          dialogClassName="modal-md modal-features"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body dir="ltr">
            <div className="text-center">
              <img
                src="https://promo.slowly.app/promo_2024_features-header.png"
                style={{ width: 240, maxWidth: "50%" }}
              />
            </div>
            <div className="d-flex w-100 my-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-mailbox.png")}
                  alt="More Open Letters"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">More Open Letters</h5>
                Receive and publish more simultaneously
              </div>
            </div>
            <div className="d-flex w-100 my-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-pt1.png")}
                  alt="Advanced Search Filters"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">Advanced Search Filters</h5>
                Filter by specific cities, exact topics, and more
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-pt-coins.png")}
                  alt="900 Coins on Registration"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">900 Coins on Registration</h5>
                Plus 100 free coins every month
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-cool.png")}
                  alt="Ad-Free Experience"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">Ad-Free Experience</h5>
                Enjoy Slowly without interruptions
              </div>
            </div>

            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-pt3.png")}
                  alt="Unlimited Styles"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">Unlimited Styles</h5>
                Unlock all avatar items for free
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-pt2.png")}
                  alt="Double Limits"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">Double Limits</h5>
                Increase friend limit, media quota, and more
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-pt4.png")}
                  alt="Enhanced Media Sharing"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">10x More Media Sharing with Quality Boost</h5>
                Share high-resolution photos and extended audio notes
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-pt-gift.png")}
                  alt="Plus Privileges
"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">Plus Privileges</h5>
                Access Plus-only avatar items and achievement stamps
              </div>
            </div>
            <div className="d-flex w-100 mb-3">
              <div className="px-2">
                <img
                  style={{ width: 26, height: 26 }}
                  src={require("../assets/images/plus-heart.png")}
                  alt="more"
                />
              </div>
              <div className="flex-fill">
                <h5 className="mb-0">And More…</h5>
                Enjoy discounted limited-time stamps, premium Gift Boxes, etc.
              </div>
            </div>

            <div
              className="btn btn-primary btn-lg link btn-block mt-5"
              onClick={this.claimOffer}
            >
              <strong>Get the Offer</strong>
            </div>
            <div
              className="text-right text-lighter position-absolute"
              style={{ top: 0, right: 0 }}
            >
              <div className="link p-3" onClick={this.hideAllModals}>
                <i className="icon-close" style={{ fontSize: 20 }} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {!loggedIn && (
          <Modal
            show={this.state.showLogin && !loggedIn}
            onHide={this.hideAllModals}
            dialogClassName="modal-md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body dir="ltr" className="p-0">
              <Login isModal onSuccess={this.onSuccessLogin} />
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale,
    loading: state.slowly.loading,
  };
};

const saveToken = function saveToken(token) {
  return {
    type: "REFRESH_TOKEN",
    token,
  };
};

const gotToken = function gotToken(token, locale = "en", trusted = false) {
  return {
    type: "GOT_TOKEN",
    token,
    trusted,
    locale,
  };
};

const changeUI = function changeUI(locale) {
  return {
    type: "SAVE_UI_LOCALE_SUCCESS",
    locale,
  };
};

const reInit = function reInit(token, newLocale = false) {
  return {
    type: "INIT_APP",
    token,
    newLocale,
  };
};

const trustDevice = function trustDevice(trust) {
  return {
    type: "TRUST_DEVICE",
    trust,
  };
};

const logout = () => {
  return {
    type: "LOGOUT",
  };
};

export default connect(mapStateToProps, {
  gotToken,
  saveToken,
  changeUI,
  reInit,
  trustDevice,
  logout,
})(Promo);
