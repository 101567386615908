import React from "react";
import Stamp from "./Stamp";
import Avatar from "./Avatar";
import emoji from "../lib/emoji";
import emojiSupport from "detect-emoji-support";
import moment from "../lib/moment";
import { API_URL } from "../config/ApiConfig";
import I18n from "../I18n";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Slider from "react-slick";
import MiniPlayer from "./MiniPlayer";
// import Highlighter from "react-highlight-words";

const attachmentPath = API_URL + "/web/attachments/";

export default class Letter extends React.PureComponent {
  stripHtml = (html) => {
    var temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = html;
    if (emojiSupport())
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || ""
      );
    else
      return emoji(
        temporalDivElement.textContent || temporalDivElement.innerText || ""
      );
  };

  render() {
    const { item, stampInfo = {}, p, u } = this.props;
    if (!item) return null;
    const imgs = !!item.attachments ? item.attachments.split(",") : [];
    // const pClass = this.rtlDetect(item.body.charAt(0)) ? "text-rtl" : "text-ltr"
    const msg = item.type === 3 ? item.body.split("------")[1] : item.body;

    const sliderSettings = {
      dots: true,
      infinite: true,
      // lazyLoad: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      // onLazyLoad: ()=>{
      //   global.log('all images loaded')
      // }
    };

    // const extra = !!this.props.extra ? map(this.props.extra, o => o.file_path ) : []

    return (
      <div
        // show={show} onHide={handleClose}
        className="letter shadow-sm position-relative mb-5"
      >
        {item.type === 3 && (
          <div className="bg-gift">
            <img
              alt="..."
              src={require("../assets/images/happy-bg.png")}
              className="w-100"
            />
          </div>
        )}
        <div className="modal-header">
          <div className="row w-100 p-0 m-0">
            <div className="col">
                { item.type===7 && (
                  <div className="text-secondary small font-weight-bold ml-n3 mt-2">{I18n.t('OPEN_LETTER')}</div>
                )}
            </div>
            <div className="col text-right p-0 mr-n3 relative">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip>
                    <div className="p-1 stamp-pop text-left">
                      <h6 className="text-white font-weight-bold mb-0 mt-2">
                        {I18n.t(stampInfo.name)}
                      </h6>
                      <div className="small">
                        {!!stampInfo.country && !stampInfo.item_set_id
                          ? I18n.country(stampInfo.country)
                          : I18n.t(stampInfo.desc)}
                      </div>
                      <div className="mt-2">
                        {stampInfo.price >= 2 && (
                          <span className="badge badge-pill badge-tag badge-active">
                            {I18n.t("STAMP_PREMIUM")}
                          </span>
                        )}
                        {!!stampInfo.item_set && (
                          <span className="badge badge-pill badge-tag text-white">
                            {I18n.t("STAMP_SET")}
                          </span>
                        )}
                        {stampInfo.item_group === "achievement" && (
                          <span className="badge badge-pill badge-tag text-white">
                            {I18n.t("STAMP_ACHIEVEMENTS")}
                          </span>
                        )}
                        {(stampInfo.item_group === "int-days" ||
                          stampInfo.item_group === "festive") && (
                          <span className="badge badge-pill badge-tag text-white">
                            {I18n.t("STAMP_SPECIAL_DAYS")}
                          </span>
                        )}
                        {!!stampInfo.country && (
                          <span className="badge badge-pill badge-tag text-white">
                            {I18n.t("STAMP_LOCATION_BASED")}
                          </span>
                        )}
                      </div>
                    </div>
                  </Tooltip>
                }
              >
                <button className="btn btn-default p-0">
                  <Stamp slug={item.stamp} name={item.stamp} size={100} />
                </button>
              </OverlayTrigger>
              <div className="chop print-hidden">
                <div className="chop-img" />
                {!!item.sent_from ? item.sent_from : item.location_code}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-body" dir="auto">
          {item.type === 3 && (
            <div className="h4 text-primary mb-5">
              {emoji("🎁")} {I18n.t("GIFT_MESSAGE_TITLE", { name: item.name })}
            </div>
          )}
          <div className="pre-wrap mb-3">
            {emojiSupport() ? msg : emoji(msg)}
            {/* { searchText==='' || searchText===null ?
              emoji(msg) :
              (
                <Highlighter
                   highlightClassName="highlight"
                   searchWords={[searchText]}
                   autoEscape={true}
                   textToHighlight={msg}
                />
              )
            } */}
          </div>
          {imgs.length > 1 ? (
            <div className="slider" key={"slider-" + item.id}>
              <Slider {...sliderSettings}>
                {imgs.map((img) => {
                  return (
                    <div className="slide d-flex align-items-center" key={img}>
                      <img
                        src={attachmentPath + img + "?_p=" + p + "&_u=" + u}
                        alt={item.name}
                        className="img-thumbnail"
                      />
                      {/* { (extra.indexOf(img)>=0) && (
                            <div className="hq-badge">
                              <i className="icon-high_quality text-white d-block" />
                            </div>
                          )} */}
                    </div>
                  );
                })}
              </Slider>
            </div>
          ) : (
            imgs.length === 1 && (
              <div className="slider" key={"slider-" + imgs[0]}>
                <div className="slide d-flex align-items-center">
                  <img
                    src={attachmentPath + imgs[0] + "?_p=" + p + "&_u=" + u}
                    alt={item.name}
                    className="img-thumbnail"
                    key={imgs[0]}
                  />
                  {/* { (extra.indexOf(imgs[0])>=0) && (
                    <div className="hq-badge">
                      <i className="icon-high_quality text-white d-block" />
                    </div>
                  )} */}
                </div>
              </div>
            )
          )}
          {item.type === 5 && !!this.props.audio && (
            <MiniPlayer
              key={this.props.audio}
              url={this.props.audio}
              duration={this.props.duration}
            />
          )}
        </div>
        <div className="modal-footer d-flex align-items-baseline">
          <div className="media flex-grow-1">
            <Avatar
              key={item.id}
              uid={item.id}
              id={item.avatar}
              gender={item.gender}
              name={item.name}
              size={60}
            />
            <div className="media-body mx-3 mt-2">
              <h5 className="mb-1 text-primary">{item.name}</h5>
              <p>
                {moment.custom(item.deliver_at, "long")}
                <br />
                <i className="icon-pin text-lighter"></i>{" "}
                {I18n.country(
                  !!item.sent_from ? item.sent_from : item.location_code
                )}
              </p>
            </div>
          </div>

          <div
            className="link p-2 mt-auto mb-3"
            onClick={this.props._deleteLetter}
          >
            <i className="icon-trash-o h5 text-lighter print-hidden" />
          </div>
        </div>
      </div>
    );
  }
}
