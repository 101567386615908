import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import Stamp from "../components/Stamp";
import {
  reProfileMe,
  reStampBookmarks,
  reMyItems,
  reAchievements,
} from "../selectors";
import {
  getStampData,
  hideStamp,
  getHiddenStamps,
  unhideStamps,
} from "../api/me.api";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import flags from "../lib/flags";
import Emoji from "../components/Emoji";
import ShowStamp from "../components/ShowStamp";
import AppAlert from "../components/AppAlert";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

import _ from "lodash";

const icons = {
  STAMP_ALL: "stamp-small-01",
  STAMP_ACHIEVEMENTS: "trophy",
  STAMP_PREMIUM: "stars",
  NEW: "hot",
  STAMP_SPECIAL_DAYS: "calendar",
  STAMP_SET: "grid",
  STAMP_LOCATION_BASED: "pin",
  HIDDEN_STAMPS: "eye-off",
};

class StampStore extends Component {
  constructor(props) {
    super(props);
    const index = _.findIndex(this.props.myItems, { isNew: true });

    this.state = {
      newStamps: index >= 0,
      currentTab: null,
      fav: this.props.me.fav,
      bookmarks: this.props.me.bookmarks,
      sortBy: this.props.sorting,
      items: [],
      myItemsByKey: _.keyBy(this.props.myItems, "item_slug"),
      counter: {
        NEW: this.props.myItems.filter((o) => !!o.isNew).length,
        STAMP_ALL: this.props.myItems.length,
        STAMP_ACHIEVEMENTS: this.props.achievements.unlocked,
        STAMP_PREMIUM: _.filter(this.props.myItems, function (o) {
          return o.price >= 2;
        }).length,
        STAMP_SET: _.filter(this.props.myItems, function (o) {
          return !!o.is_stamp_set;
        }).length,
        STAMP_SPECIAL_DAYS: _.filter(this.props.myItems, function (o) {
          return o.item_group === "int-days" || o.item_group === "festive";
        }).length,
        STAMP_LOCATION_BASED: _.filter(this.props.myItems, (o) => !!o.country)
          .length,
      },
      unhideItems: [],
      dontaskValue: this.props.dontask,
    };

    this.autoSaveDelayed = _.debounce(this.autoSave, 10000);
    this.onBodyChangeDelayed = _.throttle(this.searchNow, 500);
  }

  componentDidMount() {
    this.switchTab(
      !!this.state.newStamps
        ? "NEW"
        : this.props.bookmarks.length
        ? "STAMP_BOOKMARK"
        : "STAMP_ALL"
    );
    this._syncData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.myItems.length !== this.props.myItems.length) {
      const index = _.findIndex(this.props.myItems, { isNew: true });
      const originalNewStampsStatus = !!this.state.newStamps;

      this.setState(
        {
          newStamps: index >= 0,
          myItemsByKey: _.keyBy(this.props.myItems, "item_slug"),
          counter: {
            NEW: this.props.myItems.filter((o) => !!o.isNew).length,
            STAMP_ALL: this.props.myItems.length,
            STAMP_ACHIEVEMENTS: this.props.achievements.unlocked,
            STAMP_PREMIUM: _.filter(this.props.myItems, function (o) {
              return o.price >= 2;
            }).length,
            STAMP_SET: _.filter(this.props.myItems, function (o) {
              return !!o.is_stamp_set;
            }).length,
            STAMP_SPECIAL_DAYS: _.filter(this.props.myItems, function (o) {
              return o.item_group === "int-days" || o.item_group === "festive";
            }).length,
            STAMP_LOCATION_BASED: _.filter(
              this.props.myItems,
              (o) => !!o.country
            ).length,
          },
          loading: false,
        },
        () => {
          if (!originalNewStampsStatus && index >= 0) {
            this.switchTab("NEW");
          }
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.clearNewStamps();
    this.props.clearNotify();
    this.autoSaveDelayed.flush();
  }

  showStamp = (stamp) => {
    this.setState({
      stamp,
    });
  };

  closeStamp = () => {
    this.setState({
      stamp: null,
      loading: false,
    });
  };

  _syncData = async () => {
    const { token } = this.props.me;
    const { myItemsByKey } = this.state;
    try {
      const { bookmarks, fav } = await getStampData({ token });
      const _bookmarks = _.filter(bookmarks, (b) => !!myItemsByKey[b]);
      const _fav = _.filter(fav, (s) => !!myItemsByKey[s]);

      this.setState({
        bookmarks: _bookmarks,
        fav: _fav,
      });

      this.props.syncBookmarks(bookmarks);
      this.props.syncFav(fav);
    } catch (e) {
      global.log("syncBookmarks", e);
    }
  };

  changeSorting = (value) => {
    global.log("changeSorting", value);
    if (value === "default") {
      this.setState({ sortBy: null }, () => {
        this.switchTab(this.state.currentTab, true);
        this.props.saveSorting(null);
      });
    } else {
      this.setState({ sortBy: "new" }, () => {
        this.switchTab(this.state.currentTab, true);
        this.props.saveSorting("new");
      });
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  toggleFav = (item) => {
    if (
      this.state.fav.length >= 10 &&
      this.state.fav.indexOf(item.item_slug) < 0
    ) {
      toast.info(I18n.t("FAV_STAMPS_MAX_REACHED"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        closeOnClick: true,
      });
      return true;
    }

    const fav =
      this.state.fav.indexOf(item.item_slug) >= 0
        ? _.without(this.state.fav, item.item_slug)
        : [item.item_slug, ...this.state.fav];
    this.setState(
      {
        fav,
        favTouched: true,
      },
      this.autoSaveDelayed
    );
  };

  toggleBookmark = (item) => {
    const bookmarks =
      this.state.bookmarks.indexOf(item.item_slug) >= 0
        ? _.without(this.state.bookmarks, item.item_slug)
        : [item.item_slug, ...this.state.bookmarks];
    this.setState(
      {
        bookmarks,
        bookmarksTouched: true,
      },
      this.autoSaveDelayed
    );
  };

  closeAlert = () => {
    this.setState({
      alert: null,
    });
  };

  toggleItem = (itemId) => {
    if (!!this.state.loading) return false;

    if (this.state.unhideItems.indexOf(itemId) >= 0) {
      this.setState({
        unhideItems: _.without(this.state.unhideItems, itemId),
      });
    } else {
      this.setState({
        unhideItems: [...this.state.unhideItems, itemId],
      });
    }
  };

  _hideStamp = () => {
    if (this.state.loading) return false;

    if (!!this.state.dontaskValue) this._hideStampConfirmed();
    else
      this.setState({
        alert: {
          title: I18n.t("CONFIRM_HIDE_STAMP"),
          message: I18n.t("HIDE_STAMP_TIPS"),
          cancelLabel: I18n.t("CANCEL"),
          cancelAction: this.closeAlert,
          confirmLabel: I18n.t("HIDE"),
          confirmAction: this._hideStampConfirmed,
          dontask: I18n.t("DONT_SHOW_THIS_AGAIN"),
        },
      });
  };

  _hideStampConfirmed = async () => {
    this.setState({
      loading: true,
      alert: null,
    });
    const dontask = this.state.dontaskValue;
    try {
      global.log("hideStampConfimed, stamp=", this.state.stamp);
      const result = await hideStamp({
        token: this.props.me.token,
        id: this.state.stamp.id,
      });
      global.log("hide stamp result", result);
      if (result.result !== "SUCCESS")
        this.stampRemoved(this.state.stamp, true, dontask);
      else this.stampRemoved(this.state.stamp, false, dontask);

      this.closeStamp();
    } catch (error) {
      global.log("hide stamp error", error);
      this.setState({ loading: false });

      if (!!error.error) {
        toast.error(I18n.t("ERROR") + ": " + I18n.t(error.error), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          closeOnClick: true,
        });
      } else {
        toast.error(I18n.t("Network request failed"), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          closeOnClick: true,
        });
      }
    }
  };

  stampRemoved = (item, refresh = false, dontask = false) => {
    global.log("stamp removed", item);
    this.props.hideStampSuccess(item.item_slug, dontask);

    if (refresh) this.props.refreshStamps();

    const bookmarked = this.state.bookmarks.indexOf(item.item_slug) >= 0;
    const faved = this.state.fav.indexOf(item.item_slug) >= 0;

    global.log("stamp removed state", {
      bookmarked,
      faved,
      items: this.state.items,
      item,
    });

    this.setState(
      {
        items: _.reject(this.state.items, { item_slug: item.item_slug }),
        bookmarks: bookmarked
          ? _.without(this.state.bookmarks, item.item_slug)
          : this.state.bookmarks,
        fav: faved ? _.without(this.state.fav, item.item_slug) : this.state.fav,
        favTouched: faved,
      },
      () => {
        if (bookmarked) this.autoSaveDelayed();

        global.log("finished stamp remove", this.state);
      }
    );
  };

  loadHiddenStamps = async () => {
    this.setState({ loading: true, currentTab: "HIDDEN_STAMPS", items: [] });
    const { token } = this.props.me;
    const slowlyItems = _.keyBy(
      _.uniqBy(this.props.slowlyItems, "slug"),
      "slug"
    );

    const result = await getHiddenStamps({ token });
    global.log("Hidden stamps", result);
    this.setState({
      loading: false,
      searching: false,
      items: _.orderBy(
        _.map(result, (item) => ({
          ...item,
          ...slowlyItems[item.item_slug],
          item_name: slowlyItems[item.item_slug]
            ? slowlyItems[item.item_slug].name
            : item.item_name,
          desc: slowlyItems[item.item_slug]
            ? slowlyItems[item.item_slug].desc
            : null,
          created_at: item.created_at,
          id: item.id,
        })),
        ["updated_at"],
        ["desc"]
      ),
    });
  };

  switchTab = (tabName, force = false) => {
    if (!this.props.myItems) return false;
    if (tabName === this.state.currentTab && !force && !this.state.searching)
        return false;
  
    if (tabName === "HIDDEN_STAMPS") {
      if (this.state.currentTab === "HIDDEN_STAMPS") return true;
      this.loadHiddenStamps();
      return true;
    }

    if (tabName === 'FAV_STAMPS') {
      const { fav, myItemsByKey = {} } = this.state;
      console.log('state.bookmarks: ', fav);

      this.setState({
        currentTab: 'FAV_STAMPS',
        items: _.map(fav, o => myItemsByKey[o]),
      });
      return true;
    }

    if (tabName === 'STAMP_BOOKMARK') {
      const { bookmarks, myItemsByKey = {} } = this.state;
      console.log('state.bookmarks: ', bookmarks);

      this.setState({
        currentTab: 'STAMP_BOOKMARK',
        items: _.map(bookmarks, o => myItemsByKey[o]),
      });
      return true;
    }

    switch (tabName) {
      case 'NEW':
        this._sortNow(
          tabName,
          _.filter(this.props.myItems, o => !!o.isNew),
        );
        break;

      case 'STAMP_ALL':
        this._sortNow(tabName, this.props.myItems);
        break;

      case 'STAMP_PREMIUM':
        this._sortNow(
          tabName,
          _.filter(this.props.myItems, function (o) {
            return o.price >= 2;
          }),
        );
        break;

      case 'STAMP_SET':
        this._sortNow(
          tabName,
          _.filter(this.props.myItems, function (o) {
            return !!o.is_stamp_set;
          }),
        );
        break;

      case 'STAMP_ACHIEVEMENTS':
        this._sortNow(tabName, this.props.achievements.all);
        break;

      case 'STAMP_SPECIAL_DAYS':
        const items = _.filter(this.props.myItems, function (o) {
          return o.item_group === 'int-days' || o.item_group === 'festive';
        });
        if (this.state.sortBy === 'new') this._sortNow(tabName, items);
        else
          this._sortNow(
            tabName,
            _.orderBy(
              items,
              ['item_group', 'country', 'month', 'day', 'first_release'],
              ['desc', 'asc', 'asc', 'asc', 'asc'],
            ),
          );
        break;

      case 'STAMP_LOCATION_BASED':
        const locItems = _.filter(this.props.myItems, o => !!o.country);

        if (this.state.sortBy === 'new') this._sortNow(tabName, locItems);
        else
          this._sortNow(
            tabName,
            _.orderBy(
              locItems,
              [
                'country',
                'price',
                'item_group',
                'month',
                'day',
                'first_release',
              ],
              ['asc', 'desc', 'asc', 'asc', 'asc', 'asc'],
            ),
          );
        break;

      default:
        break;
    }

    return true;
  };

  _sortNow = (tabName, items) => {
    this.setState({
      currentTab: tabName,
      items:
        this.state.sortBy === 'new'
          ? _.orderBy(
              items,
              ['unlocked', 'created_at', 'id'],
              ['desc', 'desc', 'desc'],
            )
          : items,
    }, this.scrollToTop);
  };

  // switchTab = (tabName, force = false) => {
  //   if (tabName === this.state.currentTab && !force && !this.state.searching)
  //     return false;

  //   if (tabName === "HIDDEN_STAMPS") {
  //     if (this.state.currentTab === "HIDDEN_STAMPS") return true;
  //     this.loadHiddenStamps();
  //     return true;
  //   }

  //   if (tabName === "FAV_STAMPS") {
  //     if (this.state.currentTab === "FAV_STAMPS") return true;
  //     const { fav, myItemsByKey = {} } = this.state;
  //     this.setState(
  //       {
  //         currentTab: "FAV_STAMPS",
  //         searching: false,
  //         items: _.map(fav, (o) => myItemsByKey[o]),
  //       },
  //       this.scrollToTop
  //     );
  //     return true;
  //   }

  //   if (tabName === "STAMP_BOOKMARK") {
  //     if (this.state.currentTab === "STAMP_BOOKMARK") return true;
  //     const { bookmarks, myItemsByKey = {} } = this.state;
  //     this.setState(
  //       {
  //         currentTab: "STAMP_BOOKMARK",
  //         searching: false,
  //         items: _.map(bookmarks, (o) => myItemsByKey[o]),
  //       },
  //       this.scrollToTop
  //     );

  //     return true;
  //   }

  //   let items = [];

  //   switch (tabName) {
  //     case "NEW":
  //       items = this.props.myItems.filter((o) => !!o.isNew);
  //       break;

  //     case "STAMP_ALL":
  //       items = this.props.myItems;
  //       break;

  //     case "STAMP_PREMIUM":
  //       items = _.filter(this.props.myItems, function (o) {
  //         return o.price >= 2;
  //       });
  //       break;

  //     case "STAMP_SET":
  //       items = _.filter(this.props.myItems, function (o) {
  //         return !!o.item_set;
  //       });
  //       break;

  //     case "STAMP_ACHIEVEMENTS":
  //       items = this.props.achievements.all;
  //       break;

  //     case "STAMP_SPECIAL_DAYS":
  //       items = _.filter(this.props.myItems, function (o) {
  //         return o.item_group === "int-days" || o.item_group === "festive";
  //       });
  //       break;

  //     case "STAMP_LOCATION_BASED":
  //       items = _.sortBy(
  //         _.filter(this.props.myItems, (o) => !!o.country),
  //         ["country", "created_at"]
  //       );
  //       break;

  //     default:
  //       break;
  //   }

  //   if (this.state.sortBy === "new")
  //     items = _.orderBy(
  //       items,
  //       ["unlocked", "created_at", "id"],
  //       ["desc", "desc", "desc"]
  //     );

  //   this.setState(
  //     {
  //       currentTab: tabName,
  //       searching: false,
  //       items,
  //     },
  //     this.scrollToTop
  //   );
  // };

  renderItem = (item, index) => {
    if (this.state.currentTab === "HIDDEN_STAMPS") {
      return (
        <div className="col-6 mb-2" key={"item-" + item.item_slug}>
          <div className="d-flex align-items-center">
            <div
              className="px-2 link"
              onClick={() => {
                this.toggleItem(item.id);
              }}
            >
              <i
                className={
                  this.state.unhideItems.indexOf(item.id) >= 0
                    ? "icon-checkbox-checked text-primary"
                    : "icon-checkbox-unchecked text-lighter"
                }
              />
            </div>
            <div
              className="store-item-img m-1 link"
              onClick={() => {
                this.toggleItem(item.id);
              }}
            >
              <Stamp slug={item.item_slug} size={70} />
            </div>
            <div className="ml-2 my-0 store-item-desc">
              <h6
                className="mb-1 link"
                onClick={() => {
                  this.toggleItem(item.id);
                }}
              >
                {I18n.t(item.item_name)}
              </h6>
              <p className="small mb-0 singleline-desc">
                {!!item.country && !item.item_set_id
                  ? I18n.country(item.country)
                  : I18n.t(item.desc)}
              </p>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.currentTab === "STAMP_ACHIEVEMENTS" && !item.unlocked) {
      return (
        <div className="col-6 mb-3" key={"item-" + item.item_slug}>
          <div className="d-flex semi-trans">
            <div className="rounded store-item-img m-1">
              <img
                src={require("../assets/images/stamp/locked.png")}
                width="100"
                height="100"
                alt={I18n.t("LOCKED")}
              />
            </div>
            <div className="ml-0 m-3 store-item-desc text-black-50">
              <h5 className="mt-2 mb-1">{I18n.t(item.item_name)}</h5>
              <p className="singleline-desc">{I18n.t(item.desc)}</p>
            </div>
          </div>
        </div>
      );
    }

    const isFav = this.state.fav.indexOf(item.item_slug) >= 0;
    const bookmarked = this.state.bookmarks.indexOf(item.item_slug) >= 0;

    if (!this.state.searching) {
      if (this.state.currentTab === "STAMP_BOOKMARK" && !bookmarked)
        return null;
      if (this.state.currentTab === "FAV_STAMPS" && !isFav) return null;
    }

    return (
      <div className="col-6 mb-3" key={"item-" + item.item_slug}>
        <div className="d-flex">
          <div
            className="rounded store-item-img m-1 link"
            onClick={() => {
              this.showStamp(item);
            }}
          >
            <Stamp slug={item.item_slug} size={100} />
          </div>
          <div className="ml-0 m-3 store-item-desc">
            <h5
              className="mt-0 mb-1 text-primary link"
              onClick={() => {
                this.showStamp(item);
              }}
            >
              {I18n.t(item.item_name)}
            </h5>
            <p className="singleline-desc">
              {!!item.country && (
                <Emoji
                  code={flags[item.country].emoji}
                  size={16}
                  className="mr-1"
                />
              )}
              {!!item.country && !item.item_set_id
                ? I18n.country(item.country)
                : I18n.t(item.desc)}
            </p>
            <p className="mb-1">
              <span className="badge pr-2">
                <i className="icon-unlock mr-1 text-lighter" />{" "}
                {item.created_at_formatted}
              </span>
              {!!item.isNew && (
                <span className="text-danger ml-2 small">{I18n.t("NEW")}</span>
              )}
            </p>
            <div>
              <span
                className={
                  !!isFav
                    ? "badge pl-2 mr-2 pr-2 link badge-danger"
                    : "badge pl-2 mr-2 pr-2 link badge-dark"
                }
                onClick={() => this.toggleFav(item, index)}
              >
                {!isFav ? (
                  <i className="icon-add text-white mr-1" />
                ) : (
                  <i className="icon-checkmark text-keep-positive mr-1" />
                )}
                <i className={
                    !!isFav
                      ? "icon-heart text-keep-positive"
                      : "icon-heart text-white"
                  } />
              </span>

              <span
                className={
                  !!bookmarked
                    ? "badge pl-2 pr-2 mr-2 link badge-secondary"
                    : "badge pl-2 pr-2 mr-2 link badge-dark"
                }
                onClick={() => this.toggleBookmark(item, index)}
              >
                {!bookmarked ? (
                  <i className="icon-add text-white mr-1" />
                ) : (
                  <i className="icon-checkmark text-keep-positive mr-1" />
                )}
                <i
                  className={
                    !!bookmarked
                      ? "icon-bookmark text-keep-positive"
                      : "icon-bookmark text-white"
                  }
                />
              </span>
            </div>

            {/* {!!item.credit && (
              <div className="text-lighter smallest mt-1">
                {I18n.t(item.credit)}
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  };

  emptyMsg = (type) => {
    if (type === "STAMP_ALL" || this.state.searching) {
      return {
        icon: "icon-stamp-small-01",
        msg: I18n.t("NOT_FOUND"),
      };
    }

    if (type === "HIDDEN_STAMPS") {
      return {
        icon: "icon-eye-off text-light",
        tips: I18n.t("HIDDEN_STAMPS"),
        msg: I18n.t("NOT_FOUND"),
      };
    }

    if (type === "FAV_STAMPS") {
      return {
        icon: "icon-heart text-danger",
        tips: I18n.t("FAV_STAMPS_TIPS"),
        msg: I18n.t("NOT_FOUND"),
      };
    }
    if (type === "STAMP_BOOKMARK") {
      return {
        icon: "icon-bookmark text-secondary",
        tips: I18n.t("BOOKMARK_STAMPS_TIPS"),
        msg: I18n.t("NOT_FOUND"),
      };
    }
    if (type === "STAMP_SET")
      return {
        icon: "icon-shop",
        msg: I18n.t("NO_STAMP_SET", { category: I18n.t(type) }),
      };

    return {
      icon: "icon-shop",
      msg: I18n.t("NO_STAMPS", { category: I18n.t(type) }),
    };
  };

  autoSave = () => {
    if (!!this.state.favTouched)
      if (this.state.fav !== this.props.me.fav)
        this.props.updateFav(this.props.me.token, this.state.fav);

    if (!!this.state.bookmarksTouched)
      if (this.state.bookmarks !== this.props.me.bookmarks)
        this.props.updateBookmarks(this.props.me.token, this.state.bookmarks);

    this.setState({
      favTouched: false,
      bookmarksTouched: false,
    });
  };

  onBodyChange = (e) => {
    this.setState({ searching: true, searchText: e.target.value })
    this.onBodyChangeDelayed()
  }

  searchNow = () => {
    const txt = this.state.searchText
    if(!this.state.searching) return false;

    const searchText = txt.trim().toLowerCase();

    this.setState({
      items: !!searchText
        ? _.filter(this.props.myItems, function (o) {
            return (
              _.includes(o.item_name.toLowerCase(), searchText) ||
              _.includes(I18n.t(o.item_name), searchText) ||
              _.includes(o.desc.toLowerCase(), searchText) ||
              _.includes(I18n.t(o.desc), txt) ||
              _.includes(o.item_slug.toLowerCase(), searchText) ||
              (!!o.country &&
                _.includes(o.country.toLowerCase(), searchText)) ||
              (!!o.country &&
                _.includes(
                  I18n.country(o.country).toLowerCase(),
                  searchText
                )) ||
              (!!o.item_group &&
                o.item_group !== "default" &&
                _.includes(o.item_group.toLowerCase(), searchText))
            );
          })
        : [],
      searching: true,
      // }, ()=>{
      // global.log('this.state.items', this.state.items)
    });
  };

  _unhideItems = async () => {
    if (!!this.state.loading) return false;

    this.setState({ loading: true });

    try {
      const result = await unhideStamps({
        token: this.props.me.token,
        ids: this.state.unhideItems,
      });
      global.log("unhideStamps result", result);

      this.setState({
        items: _.reject(
          this.state.items,
          (item) => this.state.unhideItems.indexOf(item.id) >= 0
        ),
        unhideItems: [],
      });

      this.props.refreshStamps();
    } catch (error) {
      global.log("unhide stamps api error", error);
      this.setState({
        loading: false,
      });

      toast.error(I18n.t("UNABLE_TO_REFRESH_DATA"), {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
        closeOnClick: true,
      });
    }
  };

  render() {
    const { items, newStamps = false, currentTab } = this.state;
    const menuItems = !!newStamps
      ? [
          "STAMP_ALL",
          "NEW",
          "STAMP_ACHIEVEMENTS",
          "STAMP_PREMIUM",
          "STAMP_SET",
          "STAMP_LOCATION_BASED",
          "STAMP_SPECIAL_DAYS",
          "HIDDEN_STAMPS",
        ]
      : [
          "STAMP_ALL",
          "STAMP_ACHIEVEMENTS",
          "STAMP_PREMIUM",
          "STAMP_SET",
          "STAMP_LOCATION_BASED",
          "STAMP_SPECIAL_DAYS",
          "HIDDEN_STAMPS",
        ];

    const noStampsMsg = this.emptyMsg(currentTab);

    return (
      <div className="w-100">
        <div className="container">
          <div className="row">
            <div className="col-3">
              <div className="sticky-top pt-3 sidebar">
                <div className="card">
                  <div className="card-header pt-1 pb-1">
                    <div className="d-flex align-items-center">
                      <div className="h5 mb-0">{I18n.t("MY_STAMPS")}</div>
                      <div className="ml-auto pt-1 pb-1 pr-0 h5 mb-0 mr-n2">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="toolbar"
                            id="dropdown-basic"
                          >
                            <i className="icon-sort text-black-50" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              key={"default"}
                              eventKey={"default"}
                              onSelect={this.changeSorting}
                              active={this.props.sorting === null}
                            >
                              {I18n.t("SYSTEM_DEFAULT")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              key={"new"}
                              eventKey={"new"}
                              onSelect={this.changeSorting}
                              active={this.props.sorting === "new"}
                            >
                              {I18n.t("NEWEST_FIRST")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <ul className="list-group list-group-flush">
                    <div className="list-group-item bg-stable-darker p-2 border-light">
                      <div className="input-group search-bar bg-white">
                        <div className="input-group-prepend">
                          <i className="input-group-text icon-search" />
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={I18n.t("SEARCH")}
                          ref={(input) => {
                            this.searchInput = input;
                          }}
                          onChange={this.onBodyChange}
                        />
                      </div>
                    </div>
                    {menuItems.map((item) => {
                      return (
                        <button
                          type="button"
                          key={item}
                          onClick={() => {
                            this.switchTab(item);
                          }}
                          className={
                            "list-group-item d-flex justify-content-between align-items-center list-group-item-action link " +
                            (currentTab === item && !this.state.searching
                              ? "active"
                              : "")
                          }
                        >
                          <span>
                            {!!icons[item] && (
                              <i
                                className={
                                  "icon-fixed-width mr-2 icon-" + icons[item]
                                }
                              />
                            )}
                            {I18n.t(item)}
                          </span>
                          {this.state.counter[item] >= 0 && (
                            <span
                              className={
                                item === "STAMP_ALL"
                                  ? "badge badge-pill badge-secondary"
                                  : "badge badge-pill badge-light"
                              }
                            >
                              {this.state.counter[item]}
                            </span>
                          )}
                        </button>
                      );
                    })}
                  </ul>
                </div>
                <div className="d-flex mt-2 btn-group" role="group">
                  <button
                    type="button"
                    className={
                      currentTab === "FAV_STAMPS"
                        ? "btn btn-danger"
                        : "btn btn-light"
                    }
                    onClick={() => {
                      this.switchTab("FAV_STAMPS");
                    }}
                  >
                    <i
                      className={
                        currentTab !== "FAV_STAMPS"
                          ? "icon-fixed-width icon-heart mr-2 text-danger"
                          : "icon-fixed-width icon-heart mr-2"
                      }
                    />
                    <span className="badge badge-light badge-pill">
                      {this.state.fav.length}
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      currentTab === "STAMP_BOOKMARK"
                        ? "btn btn-secondary"
                        : "btn btn-light"
                    }
                    onClick={() => {
                      this.switchTab("STAMP_BOOKMARK");
                    }}
                  >
                    <i
                      className={
                        currentTab !== "STAMP_BOOKMARK"
                          ? "icon-fixed-width icon-bookmark mr-2 text-secondary"
                          : "icon-fixed-width icon-bookmark mr-2"
                      }
                    />
                    <span className="badge badge-light badge-pill">
                      {this.state.bookmarks.length}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-9">
              {!this.state.searching &&
                (currentTab === "FAV_STAMPS" ||
                  currentTab === "STAMP_BOOKMARK" ||
                  currentTab === "HIDDEN_STAMPS") && (
                  <div className="mt-4">
                    <span className=" px-2">
                      <i className={noStampsMsg.icon + " mr-2"} />{" "}
                      {noStampsMsg.tips}
                    </span>
                    <hr />
                  </div>
                )}
              <div className="mt-4">
                <div
                  className="store-item-group row"
                  key={
                    !!this.state.sortBy
                      ? currentTab + "-" + this.state.sortBy
                      : currentTab
                  }
                >
                  {items.length > 0 ? (
                    items.map(this.renderItem)
                  ) : !!this.state.loading ? (
                    <div className="w-100 mt-5 text-center text-light">
                      <div className="small">
                        <span
                          className="spinner-grow spinner-grow-sm mr-2 text-warning"
                          role="status"
                          aria-hidden="true"
                        />
                        {I18n.t("LOADING")}
                      </div>
                    </div>
                  ) : (
                    <div className="w-100 mt-5 text-center text-light">
                      {noStampsMsg.icon === "icon-shop" && (
                        <i
                          className={noStampsMsg.icon + " text-lighter"}
                          style={{
                            fontSize: 64,
                          }}
                        />
                      )}
                      <p className="mt-3 ">{noStampsMsg.msg}</p>
                      {noStampsMsg.icon === "icon-shop" && (
                        <Link to="/store" className="btn btn-secondary mt-3">
                          <i className="icon-stamp-small-01 mr-2" />
                          {I18n.t("STAMP_STORE")}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ShowStamp
          stamp={this.state.stamp}
          closeStamp={this.closeStamp}
          showButtons={true}
          bookmarks={this.state.bookmarks}
          fav={this.state.fav}
          toggleFav={this.toggleFav}
          toggleBookmark={this.toggleBookmark}
          hideStamp={this._hideStamp}
        />
        <AppAlert
          alert={this.state.alert}
          dontaskToggle={() => {
            this.setState({
              dontaskValue: !this.state.dontaskValue,
            });
          }}
          dontaskValue={this.state.dontaskValue}
        />
        {currentTab === "HIDDEN_STAMPS" &&
          this.state.unhideItems.length > 0 && (
            <button
              type="button"
              className="btn btn-primary btn-lg btn-reply shadow-lg print-hidden"
              onClick={this._unhideItems}
            >
              {I18n.t("UNHIDE") + " (" + this.state.unhideItems.length + ")"}
            </button>
          )}
        {!!this.state.loading && <Loader style={{ opacity: 0.6 }} />}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    bookmarks: reStampBookmarks(state),
    myItems: reMyItems(state),
    slowlyItems: state.slowly.items,
    achievements: reAchievements(state),
    sorting: state.me.myStampsSorting,
    dontask: !!state.me.dontask_hidestamp,
  };
};

const syncBookmarks = function syncBookmarks(bookmarks) {
  return {
    type: "UPDATE_BOOKMARKS_SUCCESS",
    bookmarks,
  };
};

const updateFav = function updateFav(token, fav) {
  return {
    type: "UPDATE_FAV",
    token,
    fav,
  };
};

const updateBookmarks = function updateBookmarks(token, bookmarks) {
  return {
    type: "UPDATE_BOOKMARKS",
    token,
    bookmarks,
  };
};

const clearNewStamps = function clearNewStamps() {
  return {
    type: "CLEAR_NEW_STAMPS",
  };
};

const clearNotify = function clearNotify() {
  return {
    type: "CLEAR_NEW_STAMP_NOTIFY",
  };
};

const hideStampSuccess = function hideStampSuccess(slug, dontask) {
  return {
    type: "HIDE_STAMP",
    slug,
    dontask,
  };
};

const saveSorting = function saveSorting(sorting) {
  return {
    type: "SAVE_MYSTAMPS_SORTING",
    sorting,
  };
};

const refreshStamps = function refreshStamps() {
  return {
    type: "REFRESH_STAMPS",
  };
};

export default connect(mapStateToProps, {
  clearNotify,
  syncBookmarks,
  updateFav,
  hideStampSuccess,
  updateBookmarks,
  clearNewStamps,
  saveSorting,
  refreshStamps,
})(StampStore);
