import React from 'react';
import I18n from "../I18n";
import Avatar from "./Avatar";
import LetterSm from "./LetterSm";
import WeatherTag from "./WeatherTag";
import moment from "../lib/moment"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux";
import colors from "../assets/colors";
import emoji from '../lib/emoji'
import {
  reProfileMe,
  reFriendPosts
} from '../selectors';
import {userProfileExtend} from '../api/explore.api';

import FriendProfile from "./FriendProfile";
import WeatherModal from "./WeatherModal";
import CountryModal from "./CountryModal";
import hashids from '../lib/hashids'
import _ from 'lodash'

const filterIcons = {
  'photos' : "icon-attachment-2",
  'audio' : 'icon-mic',
  'gift': 'icon-gift',
  'unread' : 'icon-envelope'
}

class Friend extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      friendId: null,
      isVisible: false,
      refreshing: false,
      showFriendModal: false,
      showWeatherModal: false,
      showCountryModal: false,
      searchMode: false,
      searchText: '',
      ready: false
    };

    this.scrollDebounced = _.debounce(this.onScroll, 500)
    this.checkIncomingDebounced = _.debounce(this.props.checkIncoming, 10000)
  }

  refresh = () => {
    if (!!this.state.refreshing) return false;
    const { me, friend, getLetters, checkIncoming } = this.props;
    this.setState({ refreshing: true });
    getLetters(me.token, friend.id, 1);
    this.getUserExtend();
    checkIncoming();
  };

  changeFriend = async(props) => {
    const { me, friend, post, getLetters, setReplyButton } = props;
    if(!friend || !friend.id) return false;

    if(this.state.searchMode && (post.searchText==='' || post.searchText===null))
    this.props.setSearchText({ searchText: '', postID: null})

    global.log('change friend', post)

    this.setState({
      isVisible: false,
      refreshing: false,
      showFriendModal: false,
      showWeatherModal: false,
      showCountryModal: false,
      searchMode: post.searchMode,
      searchText: post.searchText,
      filter: post.filter,
      ready: false
    });

    if (post.data.length === 0 || !!friend.redownload){
      getLetters(me.token, friend.id, 1);
    }else{
      this.setState({
        ready: true
      });
    }
    this.getUserExtend(friend);

    if(friend.id!==this.state.friendId){
      this.setState({
        friendId: friend.id
      })
      setReplyButton(!!friend.user_status
        ? friend.user_status >= 1
          ? true
          : false
        : false);
    }
    this.checkIncomingDebounced()
  }

  getUserExtend = async (_friend) => {
    const {token} = this.props.me;
    const friend = _friend ? _friend : this.props.post.friend;
    try{
      const extend = await userProfileExtend({uid: friend.user_id, token});
      global.log('getUserExtend', extend)

      const myTags = this.props.me.tags;

      const sub_others = !!extend.subtopics
        ? _.filter(
            extend.subtopics.data,
            s => s.parent === 'OTHER_TOPICS' || !s.parent,
          )
        : [];

      global.log('sub_others', sub_others);

      const otherTopics = _.map(
        _.filter(extend.topics, t => {
          return myTags.indexOf(t) < 0;
        }),
        tag => {
          return {
            tag,
            subtopics: !!extend.subtopics
              ? _.filter(extend.subtopics.data, s => s.parent === tag)
              : [],
          };
        },
      );

      this.props.updateFriendExtend({
        extend,
        commonTopics: _.map(
          _.filter(extend.topics, t => {
            return myTags.indexOf(t) >= 0;
          }),
          tag => {
            return {
              tag,
              subtopics: !!extend.subtopics
                ? _.filter(extend.subtopics.data, s => s.parent === tag)
                : [],
            };
          },
        ),
        otherTopics:
          sub_others.length > 0
            ? [
                ...otherTopics,
                {
                  tag: 'OTHER_TOPICS',
                  subtopics: sub_others,
                },
              ]
            : otherTopics,
        postID: friend.id,
      });
    }catch(error){
      global.log('getfriendextend error', error)
    }
  };

  componentDidMount() {
    this.changeFriend(this.props);
    window.addEventListener("scroll", this.scrollDebounced, false);
  }

  componentWillUnmount(){
    window.removeEventListener("scroll", this.scrollDebounced, false);
    // if(this.state.searchMode) this.props.setSearchText({ searchText: '', postID: null})
  }

  onScroll = () => {
    if( this.props.post.next_page_url === null || this.props.loading || this.state.searchMode ) return false;
    if (this.hasReachedBottom()) this.handleLoadMore();
  };

  hasReachedBottom() {
    global.log('onScroll triggered', {
      offsetHeight: document.body.offsetHeight,
      scrollTop: document.body.scrollTop,
      scrollHeight: document.body.scrollHeight
    })

    return (
      document.body.offsetHeight + document.body.scrollTop >= document.body.scrollHeight
    );
  }

  componentWillReceiveProps(nextProps){
    if(this.props.postID !== nextProps.postID) this.changeFriend(nextProps);
  }

  handleLoadMore = () => {
    global.log("handleLoadMore()");

    const { post, me, getLetters, loading } = this.props;
    if (!!this.state.refreshing || !!loading) return false;

    if (post.next_page_url !== null && post.data.length !== 0) {
      getLetters(me.token, post.friend.id, post.current_page + 1);
    }
  };

  showFriend = () => {
    this.setState({
      showFriendModal: true
    })
  }

  friendModalClosed = () => {
    this.setState({
      showFriendModal: false
    })
  }

  showWeather = () => {
    this.setState({
      showWeatherModal: true
    })
  }

  weatherModalClosed = () => {
    this.setState({
      showWeatherModal: false
    })
  }

  countryModalClosed = () => {
    this.setState({
      showCountryModal: false
    })
  }

  cancelSearch = () => {
    this.setState({ searchMode: false }, ()=>{
      this.props.setSearchText({ searchText: '', postID: null })
    })
  }

  startSearching = () => {
    this.props.setSearchText({ searchText: '', postID: this.props.postID })
    setTimeout(()=>{
      this.setState({ searchMode: true, searchText: '', filter: null }, ()=>{
        this.searchInput.focus()
      })
    })
  }

  debounceSetSearchText =  event => {
    this._setSearchText(event.target.value)
  }

  _setSearchText = (searchText) => {
    this.setState({searchText});
    this.props.setSearchText({
      searchText,
      filter: this.state.filter,
      postID: this.props.postID,
    });

    setTimeout(()=>{
      const {post, me, getLetters, loading} = this.props;
      global.log({post, me, loading})
      if (!loading && post.next_page_url !== null) {
        getLetters(me.token, post.friend.id, post.current_page + 1);
      }
    })

  };

  _onSubmit = e => {
    e.preventDefault()
    return true;
  }

  showCountryModal = () => {
    this.setState({ showCountryModal: true })
  }

  render() {
    const { post, friendIds, loading, me } = this.props
    const { showFriendModal, showWeatherModal, showCountryModal } = this.state;

    global.log('me.showWeather', me.showWeather)

    const { friend } = post

    if(!friend || friendIds.indexOf(friend.id)<0) return ( <Redirect to="/home" /> )

    const withText = !!friend.customdesc ? true : !!friend.about ? true : false;
    const friendText = !!friend.customdesc ? friend.customdesc : friend.about;

    const items = post.data.map( item => (
      <LetterSm
        key={item.id}
        item={item}
        readAt={item.read_at === null ? false : true}
        letterLink={"/friend/"+hashids.encode(item.post)+"/"+hashids.encode(item.id)+'/'}
        meID={me.id}
        // searchText={post.searchText}
        refreshLetters={this.refresh}
      />
    ))

    const isRemoved = !!friend.user_status ? friend.user_status >= 1 ? false : true : true

    return (
      <div className="mt-n3">
        <div className={!!this.state.searchMode ? "friend-header-wrapper sticky-top searching" : "friend-header-wrapper"}>
          <div className="friend-header p-2 pl-2 pr-4 pb-1">
            <div className="row">
              <div className="col-9 pt-2">
                {
                  !!this.state.searchMode ? (
                    <>
                      <span
                        className="text-primary h4 mb-0 mt-1"
                      >
                        <i className="icon-search text-primary" /> {' '}{ friend.name}

                      </span>
                      <span
                        className="badge badge-light h6 link ml-2"
                        onClick={this.cancelSearch}
                      >
                        {I18n.t('CANCEL')}
                      </span>
                    </>
                  ): (
                    <span
                      className="text-primary link h4 mb-0 mt-1"
                      onClick={!this.state.searchMode && this.showFriend}
                    >
                      {friend.name}
                    </span>
                  )
                }
                {!!this.state.searchMode ? (
                  <form className="search-form" onSubmit={this._onSubmit}>
                    <div className="input-group">
                      { !!this.state.filter && (
                        <div className="input-group-prepend">
                          <span
                            className="btn btn-secondary btn-keep-radius link mr-2"
                            onClick={()=>{
                              this.setState({ filter: null },
                              () => {
                                this._setSearchText(this.state.searchText);
                              })
                            }}
                           >
                            <i className={filterIcons[this.state.filter]} />
                          </span>
                        </div>
                      )}

                      <input
                        type="text"
                        className="form-control"
                        ref={(input) => { this.searchInput = input; }}
                        defaultValue={this.props.post.searchText}
                        onChange={this.debounceSetSearchText}
                        placeholder={I18n.t('SEARCH')+'...'}
                      />
                    </div>
                  </form>
                ):(
                  <>
                    <p className="mt-1 mb-2">
                      { !!friend.plus && (
                        <i className="icon-plus-inline text-gold mr-1 ml-n1" />
                      )}
                      {friend.fav === 1 && (
                       <i className="icon-star text-calm pr-2" />
                      )}
                      { !!isRemoved ? (
                        <span className="badge badge-dark h6">
                          <i className="icon-alert ml-1 mr-1 text-calm" />
                          {I18n.t("INACTIVE_USER_TIPS", { name: friend.name })}
                        </span>
                      ): (
                        <span>
                        { friend.location_code!=='REMOVED' ? (
                          <span className='dotted link' onClick={this.showCountryModal}>
                            <i className="icon-pin text-black-50" /> { I18n.country(friend.location_code)}
                          </span>
                        ) : (
                          <span>
                            <i className="icon-pin text-black-50" /> {I18n.t('NA')}
                          </span>
                        ) }

                        {( !!friend.dob && friend.dob_privacy>-1) && (
                        <span style={!!friend.birthdaySoon? {
                          fontWeight: 'bold',
                          color: colors.calm
                        }: null}>
                          {
                            !!friend.birthdaySoon ? (
                              <i className="icon-cake text-calm pl-2 pr-1" />
                            ) : !!friend.zodiac ? (
                            <i className={"icon-" + friend.zodiac + " text-black-50 pl-2 pr-1"} />
                          ): (
                            <i className="icon-cake text-black-50 pl-2 pr-1" />
                          )}
                          {friend.dob_privacy < 3 &&
                              friend.zodiac &&
                              I18n.t(friend.zodiac)}
                            {(friend.dob_privacy === 3 ||
                              friend.dob_privacy === 5 ||
                              friend.dob_privacy === 6) &&
                              moment.custom(friend.dob, "birthday")+" " }
                            {(friend.dob_privacy >= 4 && friend.age)  &&
                              "(" + friend.age + ")"}
                        </span>
                      )}
                      </span>
                    )}

                    </p>
                    { !!withText && (
                      <p className="friend-about text-truncate">
                        { emoji(friendText)}
                      </p>
                    )}
                    <div className="h5 mb-1 ml-n1">
                      <span
                        className="badge badge-pill badge-primary badge-search link"
                        onClick={this.startSearching}
                       >
                        <i className="icon-search" />
                        &nbsp;{ ' ' +I18n.t('SEARCH')+' ' }
                      </span>
                      { ( !!friend.extend && !!friend.extend.weather && !!me.showWeather) && (
                        <WeatherTag
                          weather={friend.extend.weather}
                          name={friend.name}
                          location_code={me.location_code}
                          showModal={this.showWeather}
                        />
                      )}
                      {friend.commonTopics && friend.commonTopics.slice(0, 3).map(function(tag) {
                        return (
                          <span className="badge badge-pill badge-tag" key={tag.tag}>{I18n.t(tag.tag)}</span>);
                      })}
                      {friend.commonTopics && friend.commonTopics.length > 3 && (
                        <span className="badge badge-pill badge-tag">{friend.commonTopics.length - 3 + "+"}</span>
                      )}
                    </div>
                  </>
                )}

              </div>
              <div className="col-3 text-right">
                <Avatar
                    key={friend.id}
                    uid={friend.id}
                    id={friend.avatar}
                    gender={friend.gender}
                    name={friend.name}
                    size={80}
                    borderColor={ !isRemoved ? colors.calm : colors.borderLight}
                    onClick={this.showFriend}
                />
                { !!friend.emoji_status && (
                  <div className="emoji-status-sm">
                    {emoji(friend.emoji_status)}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <hr className="my-0" /> */}
        </div>
        <div className="friend-letters-wrapper p-2">
          <div className="row row-eq-height pt-2 pb-2">
            {
              (!this.props.loading
                && post.data.length<=0 && !!this.state.searchMode &&
                (this.state.searchText!=='' || this.state.filter )
                && this.props.post.next_page_url===null
              ) && (
                <div className="text-light p-3 ml-2">
                  {I18n.t("NOT_FOUND")}
                </div>
              )
            }
            { ( post.data.length > 0 ) ? items : !loading && !this.state.searchMode && (
              <div className="text-light p-3 ml-2">
                <i className="icon-inbox3 mr-2 "/>
                {I18n.t("NO_LETTERS_TITLE")}
              </div>
            )}
          </div>
          { !!loading && (
            <div className="text-lighter">
              <small>
              <span className="spinner-grow spinner-grow-sm mr-2 text-warning" role="status" aria-hidden="true"></span>
              {I18n.t('LOADING')}
              </small>
            </div>
          )}
          {(!!this.state.searchMode && !this.state.filter) && (
            <div>
              <span
                className="badge badge-tag link mr-2"
                onClick={() => {
                  this.setState(
                    {
                      filter: 'photos',
                    },
                    () => {
                      this._setSearchText('');
                    },
                  );
                }}>
                  <i className="icon-attachment-2 mr-1" />
                  {I18n.t('Photos')}
              </span>
              <span
                className="badge badge-tag link mr-2"
                onClick={() => {
                  this.setState(
                    {
                      filter: 'audio',
                    },
                    () => {
                      this._setSearchText('');
                    },
                  );
                }}>
                  <i className="icon-mic mr-1" />
                  {I18n.t('Audio Notes')}
              </span>
              <span
                className="badge badge-tag link"
                onClick={() => {
                  this.setState(
                    {
                      filter: 'gift',
                    },
                    () => {
                      this._setSearchText('');
                    },
                  );
                }}>
                  <i className="icon-gift mr-1" />
                  {I18n.t('Gift')}
              </span>
            </div>
          )}
        </div>
        <FriendProfile
          show={showFriendModal}
          postID={friend.id}
          handleClose={this.friendModalClosed}
        />
        { (!!friend.extend && !!friend.extend.weather && !!this.props.me.showWeather) && (<WeatherModal
          show={showWeatherModal}
          postID={friend.id}
          handleClose={this.weatherModalClosed}
          weather={friend.extend.weather}
          name={friend.name}
          location_code={me.location_code}
        />)}

        <CountryModal
          key={friend.location_code}
          show={showCountryModal}
          locale={this.props.locale}
          handleClose={this.countryModalClosed}
          location_code={friend.location_code}
        />

      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getFriendPosts = reFriendPosts();
  // const getCommonTags = reCommonTags();

  const mapStateToProps = (state, props) => {
    return {
      me: reProfileMe(state),
      post: getFriendPosts(state, props),
      // commonTags: getCommonTags(state, props),
      friend: state.contacts[props.postID],
      friendIds: state.contacts.friendIds,
      loading: state.letters.loading,
      locale: state.slowly.locale
    };
  };
  return mapStateToProps;
};

const getLetters = function getLetters(token, id, page) {
  global.log({ id, page });

  return {
    type: "GET_LETTERS",
    token,
    id,
    page
  };
};

const checkIncoming = function checkIncoming() {

  return {
    type: "CHECK_INCOMING",
  };
};

const setSearchText = function setSearchText({
  searchText,
  filter=null,
  postID,
}) {
  return {
    type: 'SET_SEARCH_TEXT',
    searchText,
    filter,
    postID,
  };
};

const updateFriendExtend = function updateFriendExtend({
  extend,
  commonTopics,
  otherTopics,
  postID,
}) {
  return {
    type: 'UPDATE_FRIEND_EXTEND',
    extend,
    commonTopics,
    otherTopics,
    postID,
  };
};

export default connect(
  makeMapStateToProps,
  {
    getLetters,
    checkIncoming,
    setSearchText,
    updateFriendExtend
  }
)(Friend);
