import React, { Component } from "react";
import I18n from "../I18n";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import Hashids from "hashids";
import QRCode from "qrcode.react";
import tracking from "../lib/tracking";
import Loader from "../components/Loader";
import { reProfileMe } from "../selectors";
import { createSub, checkSource } from "../api/payment.api";
import { REDIRECT_URL, stripe_client_key } from "../config/ApiConfig";
import _ from "lodash";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const handleBlur = () => {
  global.log("[blur]");
};
const handleChange = (change) => {
  global.log("[change]", change);
};

const handleFocus = () => {
  global.log("[focus]");
};

const handleReady = () => {
  global.log("[ready]");
};

class StripeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:
        props.cards.length > 0 ? props.cards[0].email : this.props.me.email,
      full_name:
        props.cards.length > 0 ? props.cards[0].full_name : this.props.me.name,
      selectedCard: props.cards.length > 0 ? props.cards[0].payment_id : "new",
      error: null,
      payment: "card",
      stripe: null,
    };
  }

  componentDidMount() {
    this.setup();
  }

  setup = async () => {
    const stripePromise = await loadStripe(stripe_client_key);
    this.setState({
      stripe: stripePromise,
    });
  };

  initWeChat = async () => {
    const { me, uuid, full_name } = this.props;
    const product = {
      ...this.props.product,
      title: "One Year Pass",
      sku: "plus_yearly_oneoffpass",
    };

    tracking.event("ecommerce", "begin_checkout", "wechat", {
      items: [product],
    });

    // global.log('chargeSource', result)
    const hashids = new Hashids(
      "+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B",
      30
    );
    const hashed = hashids.encode(me.id, product.recurring);

    const result = await this.state.stripe.createSource({
      type: "wechat",
      amount: product.amount_after_tax
        ? Math.round(product.amount_after_tax * 100)
        : Math.round(product.amount_total * 100),
      currency: product.currency,
      statement_descriptor: "Slowly Plus - " + I18n.t(product.title),
      owner: {
        name: !!full_name ? full_name : me.name,
        email: this.state.email,
      },
      metadata: {
        uid: me.id,
        full_name: !!full_name ? full_name : me.name,
        locale: this.props.locale.split("_")[0],
        location_code: this.props.location_code,
        email: this.state.email,
        discount_type: this.props.discount_type,
        uuid,
        oneoffplus: "true",
      },
      redirect: {
        return_url: REDIRECT_URL + "/refresh/upgrade/" + hashed,
      },
      // usage: 'reusable'
    });

    global.log("createSource", result);
    if (!!result.source) {
      if (!!result.source.wechat) {
        this.setState({
          wechat: result.source.wechat["qr_code_url"],
          source_id: result.source.id,
        });
      }
    }
  };

  cancelWeChat = () => {
    this.setState({
      wechat: null,
    });
    this.props.onCancel();
  };

  validateWechatPay = async () => {
    const { me, uuid, product } = this.props;

    if (_.isFunction(this.props.onLoad)) this.props.onLoad();

    try {
      const result = await checkSource({
        token: me.token,
        payload: {
          device_id: uuid,
          source_id: this.state.source_id,
        },
      });
      global.log("checkSource", result);

      if(!!this.props.onUpgraded){
        this.props.onUpgraded()
        return true;
      }

      // global.log('chargeSource', result)
      const hashids = new Hashids(
        "+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B",
        30
      );
      const hashed = hashids.encode(me.id, product.recurring);
      if (!!result) window.top.location.href = REDIRECT_URL + "/refresh/upgrade/" + hashed;
    } catch (e) {
      global.log(e);
      if (_.isFunction(this.props.onError))
        if (!!e.error) {
          this.props.onError({ error: e.error });
        } else {
          this.props.onError({ error: "INTERNAL_ERROR" });
        }
    }
  };

  //alipay / pay from source
  payBySource = async () => {
    const { email, full_name } = this.state;

    if (!!email) {
      if (!email.match(emailRegex)) {
        if (_.isFunction(this.props.onError))
          this.props.onError({ error: "EMAIL_INVALID" });
        return false;
      }
    } else {
      if (_.isFunction(this.props.onError))
        this.props.onError({ error: "EMAIL_INVALID" });
      return false;
    }

    const { me, uuid, currency } = this.props;
    global.log("product", this.props.product);
    // const amount = product.amount_total

    const product =
      this.state.payment === "alipay"
        ? {
            ...this.props.product,
            title: "One Year Pass",
            sku: "plus_yearly_oneoffpass",
          }
        : this.props.product;

    const hashids = new Hashids(
      "+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B",
      30
    );
    const hashed = hashids.encode(me.id, product.recurring);

    const metadata = {
      uid: me.id,
      full_name: !!full_name ? full_name : me.name,
      locale: this.props.locale.split("_")[0],
      location_code: this.props.location_code,
      email: this.state.email,
      discount_type: this.props.discount_type,
      uuid,
    };

    const result = await this.state.stripe.createSource({
      type: this.state.payment,
      amount: product.amount_after_tax
        ? Math.round(product.amount_after_tax * 100)
        : Math.round(product.amount_total * 100),
      currency,
      statement_descriptor: "Slowly Plus - " + I18n.t(product.title),
      owner: {
        name: !!full_name ? full_name : me.name,
        email: this.state.email,
      },
      metadata:
        this.state.payment === "alipay"
          ? {
              ...metadata,
              oneoffplus: "true",
            }
          : {
              ...metadata,
              plan: this.props.plan,
            },
      redirect: {
        return_url: this.props.returnURL ? this.props.returnURL + hashed + '/' : REDIRECT_URL + "/refresh/upgrade/" + hashed,
      },
      // usage: 'reusable'
    });

    global.log("createSource", result);
    if (!!result.source) window.top.location.href = result.source.redirect.url;
  };

  handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    const { stripe } = this.state;

    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        })
        .catch((e) => {
          global.log(e.error);
          throw e.error;
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  };

  handleSubmit = async (ev, elements) => {
    ev.preventDefault();
    const { me, uuid, plan, location_code } = this.props;
    const { full_name, email, selectedCard, stripe } = this.state;

    if (!full_name) {
      this.setState({
        error: "full_name",
      });
      return false;
    }

    this.setState({
      error: null,
    });

    if (!!email) {
      if (!email.match(emailRegex)) {
        if (_.isFunction(this.props.onError))
          this.props.onError({ error: "EMAIL_INVALID" });
        return false;
      }
    }
    if (_.isFunction(this.props.onLoad)) this.props.onLoad();

    if (this.state.payment === "wechat_pay") {
      this.initWeChat();
      return true;
    } else if (this.state.payment !== "card") {
      this.payBySource();
      return true;
    }
    let payment_id =
      !!selectedCard && selectedCard !== "new" ? selectedCard : null;

    if (!payment_id) {
      const cardElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (!error) {
        global.log("[PaymentMethod]", paymentMethod);
        payment_id = paymentMethod.id;
      } else {
        global.log("[createPaymentMethod error]", error);
        if (_.isFunction(this.props.onError))
          if(error.message) this.props.onError({ error: error.message, autoClose: false });
          else this.props.onError();
          
        return false;
      }
    }

    try {
      const result = await createSub({
        token: me.token,
        payload: {
          plan,
          payment_id,
          location_code,
          device_id: uuid,
          full_name,
          email,
          locale: this.props.locale.split("_")[0],
          discount_type: this.props.discount_type,
        },
      });

      global.log("createSub", result);

      const checkPaymentIntent =
        await this.handlePaymentThatRequiresCustomerAction({
          subscription: result,
          priceId: plan,
          paymentMethodId: payment_id,
        });
      global.log("checkPaymentIntent", checkPaymentIntent);

      if(!!this.props.onUpgraded){
        this.props.onUpgraded()
        return true;
      }

      const hashids = new Hashids(
        "+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B",
        30
      );
      const hashed = hashids.encode(me.id, this.props.product.recurring);

      setTimeout(() => {
        window.top.location.href = REDIRECT_URL + "/refresh/upgrade/" + hashed;
      }, 2000);
    } catch (error) {
      global.log("error");
      if (_.isFunction(this.props.onError))
        this.props.onError(
          !!error.message
            ? {
                error: error.message,
              }
            : error
        );
    }
  };

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
    });
  };

  handleNameChange = (event) => {
    this.setState({
      full_name: event.target.value,
    });
  };

  removeCard = (card) => {};

  setPayment = (p) => {
    this.setState({
      payment: p.currentTarget.value,
    });
  };

  render() {
    if (!this.state.stripe) {
      return <Loader />;
    }
    const { selectedCard, wechat } = this.state;
    const { loading, cards, product } = this.props;

    const cardElementOptions = {
      iconStyle: "solid",
      style: {
        base: {
          fontSize: "18px",
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Cutive Mono, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }

    return (
      <Elements locale={this.props.locale} stripe={this.state.stripe}>
        <ElementsConsumer>
          {({ elements }) => {
            if (!!wechat) {
              return (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                  }}
                  className="bg-stable p-3"
                >
                  <h2 className="text-primary mb-1">
                    {I18n.t("Scan to Pay 扫码支付")}
                  </h2>
                  {/* <p className="mt-3 mb-1 text-light">{I18n.t(product.title)}</p> */}
                  <h5 className="text-positive">
                    {product.symbol}
                    {!!product.amount_total
                      ? product.amount_total
                      : product.amount_after_tax}
                  </h5>

                  <div className="mb-3 card">
                    <div className="card-body text-center">
                      <QRCode value={wechat} size={260} includeMargin />
                    </div>
                  </div>

                  <button
                    type="button"
                    className="btn btn-wechat btn-block mt-2"
                    onClick={this.validateWechatPay}
                    // disabled={loading}
                  >
                    {I18n.t("REG_NEXT")}
                  </button>

                  <button
                    type="button"
                    className="btn btn-light btn-block mt-2"
                    // disabled={loading}
                    onClick={this.cancelWeChat}
                  >
                    {I18n.t("CANCEL")}
                  </button>
                </div>
              );
            } else {
              return (
                <form
                  onSubmit={(ev) => {
                    this.handleSubmit(ev, elements);
                  }}
                  className="w-100 px-1"
                >
                  <h5 className="span-priamry mb-2">
                    {I18n.t("BILLING_INFO")}
                  </h5>
                  <input
                    type="span"
                    className={
                      this.state.error === "full_name"
                        ? "form-control mb-1 in-valid"
                        : "form-control mb-1"
                    }
                    value={this.state.full_name}
                    id="full_name"
                    required
                    placeholder={I18n.t("FULL_NAME")}
                    onChange={this.handleNameChange}
                    disabled={!!loading}
                  />
                  <input
                    type="email"
                    className={"form-control mb-1 mt-2"}
                    id="email"
                    placeholder={I18n.t("EMAIL")}
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    disabled={!!loading}
                  />

                  <div className="form-group mt-3">
                    {this.props.payment_methods.length === 0 && (
                      <label className="text-lighter">
                        {cards.length >= 1
                          ? I18n.t("SAVED_CARDS")
                          : I18n.t("CREDIT_CARD")}
                      </label>
                    )}
                    {(cards.length >= 1 ||
                      this.props.payment_methods.length > 0) && (
                      <div className="mt-2 mb-3">
                        {cards.map((card, index) => {
                          return (
                            <div
                              className="form-check  mt-2"
                              key={"card-" + card.id}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="selectedCard"
                                id={"card-" + card.id}
                                value={!!card.payment_id ? card.payment_id : ""}
                                checked={
                                  this.state.selectedCard === card.payment_id
                                }
                                onChange={() => {
                                  this.setState({
                                    selectedCard: card.payment_id,
                                    payment: "card",
                                  });
                                }}
                                disabled={!!loading}
                              />
                              <label
                                className="form-check-label d-flex align-items-center"
                                htmlFor={"card-" + card.id}
                              >
                                <span className="badge badge-primary px-1 mr-1">
                                  {card.card_brand.toUpperCase()}
                                </span>
                                <span>
                                  {" "}
                                  {"..."}
                                  {card.card_last_four}{" "}
                                </span>
                              </label>
                            </div>
                          );
                        })}
                        <div className="form-check mt-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="selectedCard"
                            id={"card-new"}
                            value="new"
                            checked={this.state.selectedCard === "new"}
                            onChange={() => {
                              this.setState({
                                selectedCard: "new",
                                payment: "card",
                              });
                            }}
                            disabled={!!loading}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"card-new"}
                          >
                            {cards.length >= 1
                              ? I18n.t("ADD_CARD")
                              : I18n.t("CREDIT_CARD")}
                          </label>
                        </div>
                        {this.props.payment_methods.map((p) => {
                          {
                            /* if(p.code!=='alipay') */
                          }
                          return (
                            <div
                              className="form-check mt-2"
                              key={"payment-" + p.code}
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payment"
                                id={p.code}
                                checked={this.state.payment === p.code}
                                value={p.code}
                                onChange={() => {
                                  this.setState({
                                    selectedCard: null,
                                    payment: p.code,
                                  });
                                }}
                                disabled={!!loading}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={p.code}
                              >
                                {p.displayName}
                              </label>
                            </div>
                          );
                        })}
                        {this.props.currency === "HKD" && (
                          <div
                            className="form-check mt-2"
                            key={"payment-wechat_pay"}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payment"
                              id={"wechat_pay"}
                              checked={this.state.payment === "wechat_pay"}
                              value="wechat_pay"
                              disabled={!!loading}
                              onChange={() => {
                                this.setState({
                                  selectedCard: null,
                                  payment: "wechat_pay",
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="wechat_pay"
                            >
                              {I18n.t("WeChat Pay 微信支付")}
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                    {this.state.payment === "card" &&
                      (cards.length === 0 || selectedCard === "new") && (
                        <>
                          <CardNumberElement className="form-control" options={cardElementOptions}  />
                          <div className="row mt-2">
                            <div className="col pr-1">
                              <CardExpiryElement className="form-control" options={cardElementOptions} />
                            </div>
                            <div className="col pl-1">
                              <CardCvcElement className="form-control" options={cardElementOptions} />
                            </div>
                          </div>
                          
                          
                          {/* <CardElement
                          className="form-control"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onReady={handleReady}
                          options={{
                            iconStyle: "solid",
                            style: {
                              base: {
                                fontSize: "18px",
                                color: "#424770",
                                letterSpacing: "0.025em",
                                fontFamily: "Cutive Mono, monospace",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        /> */}
                        </>
                        
                      )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-2 mb-3"
                    disabled={loading}
                  >
                    {!!loading ? (
                      <span
                        className="spinner-grow spinner-grow-sm span-white"
                        role="status"
                      />
                    ) : (
                      I18n.t("PAY_NOW")
                    )}
                  </button>
                  {!!this.props.hideRemarks ? (
                    <div />
                  ) : this.state.payment !== "alipay" &&
                    this.state.payment !== "wechat_pay" ? (
                    <div className="text-center text-light my-3 small">
                      {I18n.t("RECURRING_BILLING")}
                    </div>
                  ) : (
                    <div className="text-center text-light my-3 small">
                      One-off Payment 一次性付款
                    </div>
                  )}
                </form>
              );
            }
          }}
        </ElementsConsumer>
      </Elements>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    me: reProfileMe(state),
    locale: state.slowly.locale,
    uuid: state.slowly.uuid,
  };
};

export default connect(mapStateToProps, {})(StripeForm);
