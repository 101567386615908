import emoji from 'react-easy-emoji'

const _emoji = (code) => {
  if(!!code){
    try{
      return emoji(code)
    }catch(error){
      return code
    }
  }else{
    return code
  }
}
export default _emoji
