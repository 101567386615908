import {API_URL, STATIC_CDN_URL, headers, handleApiErrors,  } from '../config/ApiConfig'
import Hashids from 'hashids'
import moment from 'moment-timezone';
// import * as _moment from 'moment'
import _ from 'lodash';
import I18n from '../I18n';

export const checkPollfish = ({token, locale='en', os='3', device_id='' }) => {
  return fetch(API_URL + '/pollfish/reg?token=' + token + '&locale=' + locale + '&os='+ os +'&device_id=' + device_id)
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const pollfishDecline = ({ token }) => {
  return fetch(API_URL + '/pollfish/decline?token=' + token, {
    method: 'POST'
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const regPollfish = (json) => {
  const encodedJson = encodeURIComponent(JSON.stringify(json))
  const url = `https://wss.pollfish.com/v2/device/register/true?json=${encodedJson}&dontencrypt=true` ;
  global.log(url)

  return fetch(url, {
    method: 'GET'
  }).then( response => {
    if(response.status === 204){
      return null
    }else{
      return url
    }
  })
}

export const discover = ({ country='', locale='en' }) => {
  // const webLocale = locale==='zh_Hant' ? 'zh' : 'en' //locale==='zh_Hans' ? 'cn' : locale==='pt_BR' ? 'pb' : locale;
  return fetch(`https://content.slowly.app/discover?locale=${locale}&country=${country}&v=2.2.1`, {
    method: 'GET',
    // headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getSuggestions = ({ token, page }) => {
  return fetch(API_URL + '/store/suggestions?page=' + page, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getCountry = ({ location_code, locale='en' }) => {
  const webLocale = locale==='zh_Hant' ? 'zh' : 'en' //locale==='zh_Hans' ? 'cn' : locale==='pt_BR' ? 'pb' : locale;
  const url = webLocale==='en' ? `https://content.slowly.app/api?country=${location_code}` : `https://content.slowly.app/api?country=${location_code}&lang=${webLocale}`
  return fetch(url, {
    method: 'GET',
    // headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getWP = ({ locale, utc, localeOnly='' }) => {
  return fetch(`https://slowly.app/${locale}/story-json/?v=4&cache=${utc}${localeOnly}`, {
    method: 'GET',
    // headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const getMessages = ({ token, os, language, coffee=0 }) => {
  const msgPath = API_URL + '/messages?token=' + token +'&os=' + os +'&language='+language +"&_c="+coffee
  global.log('getMessages:'+ msgPath)
  return fetch(msgPath, {
    method: 'GET',
    headers
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}
export const genOTP = ({ timestamp, uid }) => {
  var hashids = new Hashids('+DP;=SW`DGX&n|]OGoGkj/4XqPw?^Fclc2F-_V~D=rquG+L(kW_xzVR=slp+Yj;B',30);
  global.log('genOTP', { timestamp, uid })
  return hashids.encode(parseInt(timestamp),parseInt(uid),Math.floor(100000 + Math.random() * 900000));
}

export const readMessage = ({ token, message_id })=>{
  return fetch(API_URL + '/message/read?token=' + token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      message_id
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const checkOldAccount = ({ token, accountId, deviceId, isEmulator }) => {
  return fetch(API_URL + '/users/me/check?token=' + token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      accountId,
      deviceId,
      isEmulator
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const pingSlowly = () => {
  return fetch(API_URL + '/ping/',{
    timeout: 2000,
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then( res => res )
    .catch(error => {
      throw error;
    });
};

export const giftSync = ({ token, data }) => {
  return fetch(API_URL + '/store/gift/sync?token=' + token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      data
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const giftSend = ({ token, data, device_id, title, body }) => {
  return fetch(API_URL + '/store/gift/send?token=' + token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      data,
      device_id,
      title,
      body
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}


export const validate = ({ token, productId, device_id, receiptToken, os }) => {
  return fetch(API_URL + '/store/validate?token=' + token , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      productId,
      device_id,
      receiptToken,
      os
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}

export const draw = ({ token, item_set_id, device_id, timezone }) => {
  return fetch(API_URL + '/store/draw?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      item_set_id,
      device_id,
      timezone,
      exclude_items: true
    }),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getStampList = itemID => {
  const url = API_URL + '/store/set/data?item_set_id=' + itemID +'&set_info=1';
  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const checkStore = ({
  token
}) => {
  return fetch(API_URL + '/store/check', {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const purchase = ({ token, item_set_id, device_id, promo_id }) => {
  return fetch(API_URL + '/store/purchase/v2', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      item_set_id,
      device_id,
      promo_id,
    }),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const syncStore = ({
  token,
  item_set_id,
  item_type = null
}) => {
  if (!token) return false;

  return fetch(API_URL + '/store/sync?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      item_set_id,
      item_type
    })
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const checkPromo = ({ token }) => {
  return fetch(API_URL + '/store/promo?token=' + token, {
    method: 'GET',
    headers,
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getNow = ({ token, item_set_id, promo_id, device_id }) => {
  return fetch(API_URL + '/store/comingsoon/getnow', {
    method: 'POST',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      item_set_id,
      promo_id,
      device_id
    }),
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getList = ({ country, page = 1, filter, orderby, timezone }) => {
  return fetch(
    API_URL +
      '/store/list?country=' +
      country +
      '&page=' +
      page +
      '&filter=' +
      filter +
      '&orderby=' +
      orderby +
      '&timezone=' +
      timezone,
  )
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getItemSets = ({ timezone, country, ver }) => {
  global.log('getItemSets', { timezone, country, ver })
  return fetch(API_URL + '/store/sets?country=' +country +'&timezone='+timezone +'&random=1&ver='+ver)
    .then(handleApiErrors)
    .then(res => res.json())
    .then( res => res )
    .catch(error => {
      throw error;
    });
}

export const versionCheck = () => {
  return fetch(API_URL + '/check',{
    timeout: 5000,
  })
  .then(handleApiErrors)
  .then(res=>res.json())
  .then(res => res )
  .catch(error => {
    throw error;
  });
}

export const fetchSlowly = ({ ver }) => {
  return fetch(API_URL + '/slowly?app_ver=80018&ver=' + ver, {
    timeout: 5000,
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const downloadCountries = ({ locale, ver }) => {
  return fetch(`${STATIC_CDN_URL}i18n/countries/${locale}.json?ver=${ver}`, {
    timeout: 5000,
  })
    .then(response => {
      if (response.status === 404 || response.status === 403) {
        return 'NOT_FOUND';
      } else {
        return response.json();
      }
    })
    .catch(error => {
      throw error;
    });
};

export const getSpecialStamps = ({ locale, ver }) => {
  return fetch(`${STATIC_CDN_URL}i18n/special-stamps/${locale}.json?ver=${ver}`, {
    timeout: 5000,
  })
    .then(response => {
      if (response.status === 404 || response.status === 403) {
        return 'NOT_FOUND';
      } else {
        return response.json();
      }
    })
    .catch(error => {
      throw error;
    });
};

export const getExtra = ({ locale, ver }) => {
  return fetch(`${STATIC_CDN_URL}i18n/extra/${locale}.json?ver=${ver}`, {
    timeout: 5000,
  })
    .then(response => {
      if (response.status === 404 || response.status === 403) {
        return 'NOT_FOUND';
      } else {
        return response.json();
      }
    })
    .catch(error => {
      throw error;
    });
};

export const getTopics = ({ locale, ver }) => {
  return fetch(`${STATIC_CDN_URL}i18n/topics/${locale}.json?ver=${ver}`, {
    timeout: 5000,
  })
    .then(response => {
      if (response.status === 404 || response.status === 403) {
        return 'NOT_FOUND';
      } else {
        return response.json();
      }
    })
    .catch(error => {
      throw error;
    });
};

export const getLang = ({ ver, locale }) => {
  return fetch(`${STATIC_CDN_URL}i18n/ui/${locale}.json?ver=${ver}`, {
    timeout: 5000,
  })
    .then(response => {
      console.log('getLang', response.status);

      if (response.status === 404 || response.status === 403) {
        return 'NOT_FOUND';
      } else {
        return response.json();
      }
    })
    .catch(error => {
      throw error;
    });
};

export const checkName = ({ name }) => {
  return fetch(API_URL + '/users/checkName' , {
    method: 'POST',
    headers,
    body: JSON.stringify({
      name
    })
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
};

//v7.3.0
export const getComingSoon = ({ token, page=1 }) => {
  return fetch(API_URL + '/store/comingsoon/?page=' + page, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getSeries = ({ token, collection, page }) => {
  return fetch(
    API_URL + '/store/series/?collection=' + collection + '&page=' + page,
    {
      method: 'GET',
      headers: {
        ...headers,
        Authorization: 'Bearer ' + token,
      },
    },
  )
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getBestSellers = ({ token, page }) => {
  return fetch(API_URL + '/store/bestsellers/?page=' + page, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getPromos = token => {
  return fetch(API_URL + '/store/promos', {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getGlobalOverview = token => {
  return fetch(API_URL + '/store/overview', {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getStoreItemInfo = item => {
  const itemCount = item.stamps.length;
  const isSeries = itemCount === 0 && !!item.collection_type;
  const isSet = !!isSeries || itemCount > 1;
  // global.log('getting item -', item)

  return {
    showingItem: itemCount > 0 ? item.stamps[0] : {},
    isSeries,
    isSet,
    title:
      isSet || itemCount <= 0
        ? I18n.t(item.title)
        : I18n.t(item.stamps[0].name),
    desc:  !!item.country && !item.item_set_id
      ? I18n.country(item.country)
      : isSeries
      ? I18n.t('STAMP_SET_SERIES')
      : isSet
      ? I18n.t('STAMP_SET_MINIATURE_SHEET') +
        ' ( ' +
        I18n.t('STAMP_SET_ITEMS_COUNT', {
          count: itemCount,
        }) +
        ' ) '
      : itemCount > 0
      ? I18n.t(item.stamps[0].desc)
      : I18n.t(item.body),
  };
};

export const getPromoSchedule = (item, tz='Etc/UTC') => {
  if (!item) return null;
  const _now = moment();

  //is discounted item?
  if (!!item.discount && item.discount !== 1) {
    const starttime = moment.tz(item.discount.starttime, tz);
    const endtime = moment.tz(item.discount.endtime, tz);
    const isActive = starttime <= _now && endtime > _now;

    return {
      ...item.discount,
      isActive,
    };
  }

  //with schedules?
  if (!item.schedules || item.schedules.length <= 0)
    return _.pick(item, [
      'title',
      'body',
      'url',
      'discount',
      'price',
      'original_price',
    ]);

  //if only one
  if (item.schedules.length === 1) {
    // global.log(item.title + ' only got 1 schedule ', item.schedules[0])
    const starttime = moment.tz(item.schedules[0].starttime, tz);
    const endtime = moment.tz(item.schedules[0].endtime, tz);
    const isActive = starttime <= _now && endtime > _now;
    const comingSoon = !isActive && starttime > _now && starttime < moment().add(21,'days')

    return {
      ...item.schedules[0],
      isActive,
      coming: !isActive ? starttime.format() : false,
      comingSoon: !!comingSoon
    };
  }

  //if more than one
  let foundActive = false;
  _.each(item.schedules, (s, i) => {
    // global.log(item.title + ' schedule ' + i, s)
    // any active schedule?
    const starttime = moment.tz(s.starttime, tz);
    const endtime = moment.tz(s.endtime, tz);
    const isActive = starttime <= _now && endtime > _now;
    if(isActive){ 
      foundActive = s;
      return false;
    }
  });

  if(!!foundActive) {
    global.log(item.title +
      ' found active schedule')
    return {
      ...foundActive,
      isActive: true,
      coming: false,
    };
  }

  //found the closest schedule
  const currentMonth = moment().format('M');
  const currentDay = moment().format('D');

  global.log(
    item.title +
      ' has more than 1 schedules, sort now. current month: ' +
      currentMonth,
  );

  const _sorted = _.sortBy(item.schedules, [s => {
    const monthDiff = moment(s.starttime).format('M') - currentMonth;
    // global.log('Month' + moment(s.starttime).format('M') +' monthDiff = ' + monthDiff)
    if (monthDiff === 0) {
      if (moment(s.starttime).format('D') - currentDay > 0) {
        return 0;
      } else {
        return 12;
      }
    }
    // global.log('final _monthDiff: ' + _monthDiff)
    return monthDiff < 0 ? monthDiff + 12 : monthDiff;
  }]);
  // global.log( item.title + ' unsorted', item.schedules);
  // global.log( item.title + ' _sorted', _sorted);
  const starttime = moment.tz(_sorted[0].starttime, tz);
  const endtime = moment.tz(_sorted[0].endtime, tz);
  const isActive = starttime <= _now && endtime > _now;
  const comingSoon = !isActive && starttime > _now && starttime < moment().add(21,'days')

  if (_sorted[0])
    return {
      ..._sorted[0],
      isActive,
      coming: !isActive && starttime.format(),
      comingSoon: comingSoon
    };

  global.log('Nothing found, return original item');

  return _.pick(item, [
    'title',
    'body',
    'url',
    'discount',
    'price',
    'original_price',
  ]);
};

export const getExclusive = ({ token, page }) => {
  return fetch(API_URL + '/store/exclusive?page=' + page, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};

export const getAllStamps = ({ token, mode, page }) => {
  return fetch(API_URL + '/store/all?mode=' + mode + '&page=' + page, {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
    },
  })
    .then(handleApiErrors)
    .then(res => res.json())
    .then(res => res)
    .catch(error => {
      throw error;
    });
};


export const eventLog = ({ token, payload }) => {
  global.log('eventLog payload', payload);
  return fetch(API_URL + '/store/event?token=' + token, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload)
  })
  .then(handleApiErrors)
  .then(res => res.json())
  .then( res => res )
  .catch(error => {
    throw error;
  });
}
